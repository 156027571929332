import React from "react";
import { Link } from "react-router-dom";

function Home() {
  return (
    <>
      {/* <!---------------------------------------------------- Header include Start----------------------------------- --> */}
      <nav
        className="navbar navbar-expand-md mainnavbar"
        aria-label="Offcanvas navbar large"
      >
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
            <img
              className="w-100 h-100"
              src="/assets1/image/logo/logo.png"
              alt="logo"
            />
          </a>
          <button
            className="navbar-toggler shadow-none border-0"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar2"
            aria-controls="offcanvasNavbar2"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="offcanvas offcanvas-end text-bg-dark"
            tabIndex="-1"
            id="offcanvasNavbar2"
            aria-labelledby="offcanvasNavbar2Label"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasNavbar2Label">
                <img
                  className="w-100 h-100"
                  src="/assets1/image/logo/logo.png"
                  alt="logo"
                />
              </h5>
              <button
                type="button"
                className="btn-close btn-close-white"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body off_out">
              <ul className="navbar-nav justify-content-center gap-4 flex-grow-1 pe-3 align-items-center">
                <li className="nav-item dropdown">
                  <a className="nav-link" href="#home">
                    Home
                  </a>
                </li>
                <li className="nav-item dropdown">
                  <a className="nav-link" href="#about">
                    About
                  </a>
                </li>
                <li className="nav-item dropdown">
                  <a className="nav-link" href="#roadmap">
                    Roadmap
                  </a>
                </li>
                <li className="nav-item dropdown">
                  <a className="nav-link" href="#Token">
                    Token
                  </a>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link "
                    target="_blank"
                    href="./aexnwhitepaper.pdf"
                  >
                    White Paper
                  </a>
                </li>
              </ul>
              <div className="lastheaderbutton d-flex align-items-center gap-4">
                <Link to="/register"
                  className="btn btn-theme1 px-4 py-2 fw-bold text-black btn3 fw-bold d-block d-md-none d-lg-block text-nowrap"
                >
                  Register
                </Link>
                <Link
                  to="/login"
                  className="btn btn-theme1 px-4 py-2 fw-bold text-black btn3  fw-bold d-block d-md-none d-lg-block text-nowrap"
                >
                  Login
                </Link>
              </div>
            </div>
          </div>
        </div>
      </nav>
      {/* <!----------------------------------------------------- Header include End ---> */}
      <div className="container-fluid homesection py-lg-4 py-md-3 py-2">
        <div className="row pb-5">
          <div className="col-md-6 col-md-6 d-flex  flex-column  order-2 order-md-1">
            <h1 className="heading">
              WELCOME TO<span> STRIVE PRO </span> WORLD’S MOST ADVANCED DECENTRALISED PLATFORM{" "}
            </h1>
            <p className="perograpg mb-lg-3">
              Embark on a Journey to Financial Empowerment with Strive Pro
              Coin: A Trailblazing Digital Currency Built on Transparency,
              Security, and Innovation."
            </p>
            <div className="button gap-2 d-flex align-items-center py-lg-4">
              <Link
                to="/login"
                className="btn buttonmain w-auto px-5 py-2 text-white"
              >
                Login
              </Link>
              <Link
                to="/register"
                className="btn buttonmain w-auto px-5 py-2 text-white"
              >
                Register
              </Link>
            </div>
           
          </div>
          <div className="col-md-6 order-1 order-md-2 position-relative">
            <div className="heroimage">
              <img
                className="w-100 h-100 px-lg-5"
                src="/assets1/image/home/hero.png"
                alt="hero"
              />
            </div>
           
          </div>
        </div>
        <div className="d-none d-md-block d-lg-none d-xl-none d-xxl-none">
          <div className="row mx-0 bottomsection  ">
            <div className="col-sm-4 px-1">
              <div className="card bg-transparent border-0">
                <div className="card-image">
                  <img
                    className="w-100 h-100"
                    src="./assets1/image/icon/icon1.png"
                    alt="icon1"
                  />
                </div>
                <div className="card-body">
                  <div className="card-title text-white fw-bold">+128K</div>
                  <div className="card-sub-title">Current Holders</div>
                </div>
              </div>
            </div>
            <div className="col-sm-4 px-1">
              <div className="card bg-transparent border-0">
                <div className="card-image">
                  <img
                    className="w-100 h-100"
                    src="./assets1/image/icon/icon2.png"
                    alt="icon2"
                  />
                </div>
                <div className="card-body">
                  <div className="card-title text-white fw-bold">+2 MILLION</div>
                  <div className="card-sub-title">CryptoItem</div>
                </div>
              </div>
            </div>
            <div className="col-sm-4 px-1">
              <div className="card bg-transparent border-0">
                <div className="card-image">
                  <img
                    className="w-100 h-100"
                    src="./assets1/image/icon/icon3.png"
                    alt="icon3"
                  />
                </div>
                <div className="card-body">
                  <div className="card-title text-white fw-bold">+3.000 USD</div>
                  <div className="card-sub-title">Total Volume</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container-fluid cardimagesection py-lg-4 py-md-3 py-2"
        id="about"
      >
        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="card-image p-3">
              <img
                className="w-100 h-100"
                src="./assets1/image/home/cardimage1.png"
                alt="image"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="heading text-center text-sm-start">
              "Welcome To Strive Pro Coin: Empowering The Future Of Finance"
            </div>
            <div className="perograph">
              Welcome to Strive Pro Coin, a revolutionary digital currency
              poised to transform the landscape of finance. In this forward
              section, we invite you to embark on a journey of innovation and
              discovery as we unveil our vision, values, and the roadmap that
              will guide us towards a future of financial empowerment. With
              cutting-edge technology, a dedicated team, and a steadfast
              commitment to transparency and security, we are on a mission to
              redefine the way we transact and interact with digital assets.
              Join us as we navigate the path to success, creating a world where
              financial possibilities know no bounds.
            </div>
          </div>
        </div>
      </div>
    
      <div className="container-fluid cardimagesection py-lg-4 py-md-3 py-2">
        <div className="row align-items-center mx-lg-3">
          <div className="col-lg-6 order-2 order-lg-1">
            <div className="heading text-center text-sm-start">
              BLOCKCHAIN & CRYPTOCURRENCY
            </div>
            <div className="perograph">
              Cryptocurrencies like Bitcoin and Ethereum are powered by a
              technology called the blockchain. At its most basic, a blockchain
              is a list of transactions that anyone can view and verify. The
              Bitcoin blockchain, for example, contains a record of every time
              someone sent or received bitcoin. Cryptocurrencies and the
              blockchain technology that powers them make it possible to
              transfer value online without the need for a middleman like a bank
              or credit card company.
            </div>
            <div className="perograph text-theme2">
              Imagine a global, open alternative to every financial service you
              use today, accessible with little more than a smartphone and
              internet connection.
            </div>
            <div className="perograph">
              • Almost all cryptocurrencies, including Bitcoin, Ethereum,
              Bitcoin Cash, and Litecoin, are secured via blockchain networks.
              Which means their accuracy is constantly being verified by a huge
              amount of computing power.
            </div>
           
          </div>
          <div className="col-lg-6 order-1 order-lg-2">
            <div className="card-image p-3">
              <img
                className="w-100 h-100"
                src="./assets1/image/home/cardimage2.png"
                alt="image"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid cardimagesection py-lg-4 py-md-3 py-2">
        <div className="row align-items-center mx-3">
          <div className="col-lg-6 order-1 order-lg-2">
            <div className="card-image p-3">
              <img
                className="w-100 h-100"
                src="./assets1/image/home/cardimage3.png"
                alt="image"
              />
            </div>
          </div>
          <div className="col-lg-6 order-2 order-lg-1">
            <div className="heading text-center text-sm-start">BITCOIN</div>
            <div className="perograph">
              <span className="text-theme2 fw-bold">BITCOIN</span> is a
              cryptocurrency, a virtual currency designed to act as money and a
              form of payment outside the control of any one person, group, or
              entity, and thus removing the need for third - party involvement
              in financial transactions. •Launched in 2009, Bitcoin is the
              world's largest cryptocurrency by market capitalization.
            </div>
            <div className="perograph">
              • Unlike fiat currency, Bitcoin is created, distributed, traded,
              and stored using a decentralized ledger system known as a
              blockchain.
            </div>
            <div className="perograph">
              • Bitcoin's history as a store of value has been turbulent; it has
              gone through several boom and bust cycles over its relatively
              short lifespan.
            </div>
            <div className="perograph">
              • As the earliest virtual currency to meet widespread popularity
              and success, Bitcoin has inspired a host of other cryptocurrencies
              in its wake.
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid joincomunitSection py-lg-4 py-md-3 py-2">
        <div className="row">
          <div className="col-12 text-center">
            <div className="card py-4">
              <div className="card-body">
                <div className="heading">Join Our Community</div>
                <div className="perograph py-3">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Enim
                  sed pulvinar nisl amet, viverra nulla ut. Aliquet nunc justo,
                  bibendum nisl varius fringilla odio eu. Ut et, nullam
                  tristique at ultrices. Viverra eget ultrices risus risus massa
                  adipiscing adipiscing. Integer tempus aliquam vitae urna,
                  ipsum mattis hendrerit quam.
                </div>
                <button className="btn border-0 buttonmain">JOIN Community</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    
      <div className="container-fluid cardimagesection py-lg-4 py-md-3 py-2">
        <div className="row align-items-center mx-lg-3">
          <div className="col-lg-6 order-2 order-lg-1">
            <div className="heading text-center text-sm-start">ETHEREUM</div>
            <div className="perograph">
              <span className="text-theme2 fw-bold">Ethereum </span> is a
              decentralized blockchain platform that establishes a peer -to
              -peer network that securely executes and verifies application
              code, called smart contracts. Smart contracts allow participants
              to transact with each other without a trusted central authority.
              Transaction records are immutable, verifiable, and securely
              distributed across the network, giving participants full ownership
              and visibility into transaction data. Transactions are sent from
              and received by user -created Ethereum accounts. A sender must
              sign transactions and spend Ether, Ethereum's native
              cryptocurrency, as a cost of processing transactions on the
              network.
            </div>
          </div>
          <div className="col-lg-6 order-1 order-lg-2">
            <div className="card-image p-3">
              <img
                className="w-100 h-100"
                src="./assets1/image/home/cardimage4.png"
                alt="image"
              />
            </div>
          </div>
        </div>
      </div>
      {/* <!-------------------------------------------Footer Include Start----------------------------------------> */}
      <footer className="container-fluid">
        <div className="row border-bottom border-theme1 py-5">
          <div className="col-md-5">
            <div className="footerlogo">
              <img
                className="w-100 h-100"
                src="./assets1/image/logo/logo.png"
                alt=""
              />
            </div>
            <div className="footerheading mb-2">Don’t Miss Out, Stay Updated</div>
          </div>
          <div className="col-md-2"></div>
          <div className="col-md-5">
            <div className="subheading pb-3">
              Don’t hesitate to subscribe to latest news about ICo markets as
              well as crucial financial knowledge to become successful investors
              globally
            </div>
            <ul className="d-flex align-items-center gap-3 socilalink">
              <li>
                <a href="">
                  <img
                    className="w-100 h-100"
                    src="./assets1/image/icon/twitter.svg"
                    alt="twitter"
                  />
                </a>
              </li>
              <li>
                <a href="">
                  <img
                    className="w-100 h-100"
                    src="./assets1/image/icon/facebook.svg"
                    alt="facebook"
                  />
                </a>
              </li>
              <li>
                <a href="">
                  <img
                    className="w-100 h-100"
                    src="./assets1/image/icon/telegram.svg"
                    alt="telegram"
                  />
                </a>
              </li>
              <li>
                <a href="">
                  <img
                    className="w-100 h-100"
                    src="./assets1/image/icon/youtube.svg"
                    alt="youtube"
                  />
                </a>
              </li>
              <li>
                <a href="">
                  <img
                    className="w-100 h-100"
                    src="./assets1/image/icon/instagram.svg"
                    alt="instagram"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="row py-3">
          <div className="col-12">
            <p className="text-white fs-13 mb-0 text-center">
              © 2024. All rights reserved by{" "}
              <a className="text-theme1" href="">
              Strive Pro
              </a>
            </p>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Home;
