import React from "react";
import Footer from "../Footer/Footer";
import MobileNavbar from "../Navbar/MobileNavbar";
import Navbar from "../Navbar/Navbar";
import Header from "../Header/Header";
import { useSelector } from "react-redux";

export default function SelfAddress() {
  const { Accountaddress } = useSelector((state) => state.Dashboarddata);

  return (
    <>
      <Header />
      <Navbar />
      <MobileNavbar />
      <div className="container-fluid Activate_UpgradeSectiob">
        <div className="row">
          <div className="col-12">
            <div className="text-white fw-bold HeadingOtherpage py-2">
              Self Address
            </div>
          </div>
          <main className="col-md-9 mx-auto col-lg-10 col-12 px-md-4 mt-0 vh-height">
            <div className="row mx-0">
              <div className="col-lg-7 mx-auto">
                <div className="card">
                  <a
                    href={`https://bscscan.com/address/${Accountaddress}/transactions`}
                    target="_blank"
                  >
                    <div className="card-body">
                      <div className="cardheading text-start text-sm-center">
                        Self Address
                      </div>
                      <p className="text-white">
                        Wallet Address :{Accountaddress}
                      </p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </main>
          <Footer />
        </div>
      </div>
    </>
  );
}
