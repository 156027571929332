import React from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Navbar from "../Navbar/Navbar";
import MobileNavbar from "../Navbar/MobileNavbar";

export default function StakingBusinessReport() {
  return (
    <>
      <Header />
      <Navbar />
      <MobileNavbar />
      <div className="container-fluid Activate_UpgradeSectiob tablecardSection">
        <div className="row">
          <div className="col-12">
            <div className="text-white fw-bold HeadingOtherpage my-sm-3 mb-4">
              Staking Total Business Report
            </div>
          </div>
          <main className="col-md-12 mx-auto col-lg-12 col-12 px-md-4 mt-0">
            <div className="row mx-0 ">
              <div className="col-lg-12 mx-auto">
                <div className="card">
                  <div className="card-body ">
                    <div className="mt-md-3 table_main table-responsive">
                      <table className="table mb-0">
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>Level</th>
                            <th>Total Business</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                          </tr>
                          <tr>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                          </tr>
                          <tr>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                          </tr>
                          <tr>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                          </tr>
                          <tr>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                          </tr>
                          <tr>
                            <td className="text-danger text-center" colspan="3">
                              No Record found !
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <Footer />
        </div>
      </div>
    </>
  );
}
