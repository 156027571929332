import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import Navbar from "../Navbar/Navbar";
import MobileNavbar from "../Navbar/MobileNavbar";
import Footer from "../Footer/Footer";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { loadWeb3 } from "../../Utility/contract";
import { API } from "../../Utility/API";
import { updateAuth } from "../../Redux/AuthSlice";

import {
  Abi_Main,
  Abi_Token,
  Abi_USDT,
  ContractAddress_Main,
  ContractAddress_Token,
  ContractAddress_USDT,
} from "../../Utility/ContractAddress";
import { Spinner } from "react-bootstrap";

export default function Activation() {
  const user = useSelector((state) => state.Auth.userId);
  const jwt = useSelector((state) => state.Auth.jwtToken);
  let [Amount, setAmount] = useState(0);
  let [connected, setconnected] = useState("Wallet is locked");
  let [loading, setloading] = useState(false);
  let [USDT, setUSDT] = useState(0);
  const [TokenBalance, setTokenBalance] = useState(0);
  const [BNBBalance, setBNBBalance] = useState(0);
  const [IsUpgrade, setIsUpgrade] = useState(0);
  const [LiveRate, setLiveRate] = useState(0);
  const [Wallet_Address, setWallet_Address] = useState("");
  const [TokenAmount, setTokenAmount] = useState(0);
  const [UsdtAmount, setUsdtAmount] = useState(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Loader component
  const Loader = () => (
    <div className="loader text-center">
      <Spinner animation="border" variant="primary" />
      <p>Loading...</p>
    </div>
  );

  const CalculateValue = () => {
    setAmount(IsUpgrade);
    setUsdtAmount(IsUpgrade);
  };

  const reset = async () => {
    setAmount(0);
    setTokenAmount(0);
    setUsdtAmount(0);
  };

  const GetUserDetails = async () => {
    try {
      let responce = await API?.get(`userDashboard?uid=${user}`, {
        headers: {
          Authorization: `${jwt}`,
          "Content-Type": "application/json",
        },
      });
      responce = responce.data.data[0][0];
      console.log("getUserDetails", responce);

     // setLiveRate(responce.TokenRate);
      setIsUpgrade(responce.UpgradeAmount);
      setWallet_Address(responce.Accountaddress);
    } catch (e) {
      console.log("userDetails error", e);
      // console.log("userDetails error", e.response.data);
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            wtcAuth: false,
            token: null,
            userId: null,
            ipAddress: null,
          })
        );
        navigate("/");
      }
    }
  };

  const USDTBalance = async () => {
    try {
      let acc = await loadWeb3();
      let contract = await new window.web3.eth.Contract(
        Abi_USDT,
        ContractAddress_USDT
      );
      let result = await contract.methods.balanceOf(acc.toString()).call();
      // console.log("Result ",result.toString());
      result = parseFloat(result) / parseFloat(1000000000000000000);
      // console.log("Result ", result);
      //result = window.web3.utils.fromWei(result);
      setUSDT(result.toFixed(2));
    } catch (error) {
      setloading(false);
      console.log(error);
    }
  };

  const Token_Balance = async () => {
    try {
      let acc = await loadWeb3();
      let contract = await new window.web3.eth.Contract(
        Abi_Token,
        ContractAddress_Token
      );
      let result = await contract.methods.balanceOf(acc.toString()).call();
      // console.log("Result ",result.toString());
      result = parseFloat(result) / parseFloat(1000000000000000000);
      // console.log("Result ", result);
      //result = window.web3.utils.fromWei(result);
      setTokenBalance(result.toFixed(2));
    } catch (error) {
      setloading(false);
      console.log(error);
    }
  };

  const BNB_Balance = async () => {
    try {
      let acc = await loadWeb3();

      let myBalance = 0;
      myBalance = await window.web3.eth.getBalance(acc);
      myBalance = await window.web3.utils.fromWei(myBalance, "ether");

      // console.log("BNB balance",myBalance);

      setBNBBalance(parseFloat(myBalance).toFixed(4));
    } catch (error) {
      setloading(false);
      console.log(error);
    }
  };

  const walletConnected = async () => {
    try {
      let acc = await loadWeb3();

      if (acc == "No Wallet") {
        setconnected("Wallet is locked");
      } else if (acc == "Wrong Network") {
        toast.error(" Wrong Network Please Connect BSC Network");
        setconnected("Wrong Network");
      } else {
        setconnected("Wallet is Connected");
      }
    } catch (e) {
      console.log("Error While WalletConnect", e);
    }
  };

  const Activate = async (e) => {
    let acc = await loadWeb3();
    setloading(true);
    if (acc === "No Wallet" || acc === undefined) {
      toast.error("No Wallet Connected");
      setloading(false);
    } else if (acc == "Wrong Network") {
      //toast.error("Wrong Network Please Connect BSC Scan Network");
      console.log("Wrong Network Please Connect Polygon Scan Network");
      setloading(false);
    } else {
      if (Wallet_Address.toUpperCase() == acc.toUpperCase()) {
        if (Amount !== 0) {
          if (USDT < UsdtAmount) {
            toast.error("Insufficient USDT Balance");
            setloading(false);
          } else if (TokenBalance < TokenAmount) {
            toast.error("Insufficient Token Balance");
            setloading(false);
          } else {
            // let amountValue = window.web3.utils.toWei(Amount.toString()); // parseFloat(Amount) * 1000000000000000000; // window.web3.utils.fromWei(Amount.toString());
            let usdtamountValue = window.web3.utils.toWei(
              UsdtAmount.toString(),
              "ether"
            );

            // let tokenamountValue = window.web3.utils.toWei(
            //   TokenAmount.toString(),
            //   "ether"
            // );
            const gasPrice = await window.web3.eth.getGasPrice();
            const approveBlock = window.web3.eth.getBlock("latest");

            try {
              let contract_Main = await new window.web3.eth.Contract(
                Abi_Main,
                ContractAddress_Main
              );
              let contract_USDT = await new window.web3.eth.Contract(
                Abi_USDT,
                ContractAddress_USDT
              );
              // let contract_Token = await new window.web3.eth.Contract(
              //   Abi_Token,
              //   ContractAddress_Token
              // );
              let approveCall = await contract_USDT.methods
                .approve(ContractAddress_Main, usdtamountValue)
                .send({
                  from: acc,
                  gasLimit: approveBlock.gasLimit,
                  gasPrice: await window.web3.eth.getGasPrice(),
                });
              toast.success("USDT Approved Succesful");

              // let approveCall_Token = await contract_Token.methods
              //   .approve(ContractAddress_Main, tokenamountValue)
              //   .send({
              //     from: acc,
              //     gasLimit: approveBlock.gasLimit,
              //     gasPrice: await window.web3.eth.getGasPrice(),
              //   });
              // toast.success("AGC Approved Succesful");

              let sellCall = await contract_Main.methods
                .deposit(usdtamountValue)
                .send({
                  from: acc,
                  gasLimit: approveBlock.gasLimit,
                  gasPrice: await window.web3.eth.getGasPrice(),
                });
              let res = await API.post(
                "Activate_Temp",
                {
                  uid: user,
                  Address: acc,
                  amount: Amount,
                  txnHash: sellCall.transactionHash,
                },
                {
                  headers: {
                    Authorization: `${jwt}`,
                    "Content-Type": "application/json", // Adjust the content type as needed
                  },
                }
              );
              console.log("activation", res.data);
              toast.success("Transaction is succesful");
              if (res.data.success == true) {
                toast.success("Activation is under process");
                navigate("/dashboard");
              }
            } catch (err) {
              console.log("error while calling fuction sell", err);
              if (
                e.response.data.showableMessage ==
                "Please login to access this resource"
              ) {
                dispatch(
                  updateAuth({
                    isAuth: false,
                    userId: null,
                    jwtToken: null,
                    ipAddress: null,
                  })
                );
                navigate("/");
              }
            }
          }
        } else {
          toast.error("Please Enter Amout First");
          setloading(false);
        }
      } else {
        toast.error("Invalid Wallet address");
        setloading(false);
      }
    }
  };

  const Upgrade = async (e) => {
    let acc = await loadWeb3();
    setloading(true);
    if (acc === "No Wallet" || acc === undefined) {
      toast.error("No Wallet Connected");
      setloading(false);
    } else if (acc == "Wrong Network") {
      //toast.error("Wrong Network Please Connect BSC Scan Network");
      console.log("Wrong Network Please Connect Polygon Scan Network");
      setloading(false);
    } else {
      if (Wallet_Address.toUpperCase() == acc.toUpperCase()) {
        if (Amount !== 0) {
          // if (IsUpgrade < Amount) {
          //   toast.error("You cannot upgrade with lower package");
          //   setloading(false);
          // } else 
          if (USDT < UsdtAmount) {
            toast.error("Insufficient USDT Balance");
            setloading(false);
          } else if (TokenBalance < TokenAmount) {
            toast.error("Insufficient Token Balance");
            setloading(false);
          } else {
            let usdtamountValue = window.web3.utils.toWei(
              UsdtAmount.toString(),
              "ether"
            );

            // let tokenamountValue = window.web3.utils.toWei(
            //   TokenAmount.toString(),
            //   "ether"
            // );
            const gasPrice = await window.web3.eth.getGasPrice();
            const approveBlock = window.web3.eth.getBlock("latest");

            try {
              let contract_Main = await new window.web3.eth.Contract(
                Abi_Main,
                ContractAddress_Main
              );
              let contract_USDT = await new window.web3.eth.Contract(
                Abi_USDT,
                ContractAddress_USDT
              );
              // let contract_Token = await new window.web3.eth.Contract(
              //   Abi_Token,
              //   ContractAddress_Token
              // );
              let approveCall = await contract_USDT.methods
                .approve(ContractAddress_Main, usdtamountValue)
                .send({
                  from: acc,
                  gasLimit: approveBlock.gasLimit,
                  gasPrice: await window.web3.eth.getGasPrice(),
                });
              toast.success("USDT Approved Succesful");

              // let approveCall_Token = await contract_Token.methods
              //   .approve(ContractAddress_Main, tokenamountValue)
              //   .send({
              //     from: acc,
              //     gasLimit: approveBlock.gasLimit,
              //     gasPrice: await window.web3.eth.getGasPrice(),
              //   });
              // toast.success("AGC Approved Succesful");

              let sellCall = await contract_Main.methods
                .deposit(usdtamountValue)
                .send({
                  from: acc,
                  gasLimit: approveBlock.gasLimit,
                  gasPrice: await window.web3.eth.getGasPrice(),
                });
              let res = await API.post(
                "Upgrade_temp",
                {
                  uid: user,
                  Address: acc,
                  amount: Amount,
                  txnHash: sellCall.transactionHash,
                },
                {
                  headers: {
                    Authorization: `${jwt}`,
                    "Content-Type": "application/json", // Adjust the content type as needed
                  },
                }
              );
              console.log("Upgrade", res.data);
              toast.success("Transaction is succesful");
              if (res.data.success == true) {
                toast.success("Upgrade is under process");
                navigate("/dashboard");
              }
            } catch (err) {
              console.log("error while calling fuction sell", err);
              if (
                e.response.data.showableMessage ==
                "Please login to access this resource"
              ) {
                dispatch(
                  updateAuth({
                    isAuth: false,
                    userId: null,
                    jwtToken: null,
                    ipAddress: null,
                  })
                );
                navigate("/");
              }
            }
          }
        } else {
          toast.error("Please Enter Amout First");
          setloading(false);
        }
      } else {
        toast.error("Invalid Wallet address");
        setloading(false);
      }
    }
  };

  useEffect(() => {
    walletConnected();
    USDTBalance();
    GetUserDetails();
  }, []);

  return (
    <>
      <Header />
      <Navbar />
      <MobileNavbar />
      {/*----------------------------------------Dashboard Include---------------------------------------*/}
      <div className="container-fluid Activate_UpgradeSectiob">
        <div className="row">
          <div className="col-12">
            <div className="text-white fw-bold HeadingOtherpage py-2">
              Activate / Upgrade
            </div>
          </div>
          <main className="col-md-9 mx-auto col-lg-10 col-12 px-md-4 mt-0">
            <div className="row mx-0">
              <div className="col-lg-7 col-sm-12 col-md-10 mx-auto px-0">
                <div className="card">
                  <div className="card-body">
                    <div className="cardheading text-start">
                      Activation / Upgrade
                    </div>
                    
                    <p className="fs-13 text-white">
                      Available USDT Balance : {USDT} USDT
                    </p>
                    
                    <p className="fs-13 text-theme1 fw-bold text-center border-top border-theme1 py-2">
                      {connected}
                    </p>
                    <div className="row mx-0 align-items-center justify-content-sm-between justify-content-center">
                      <div className="col-sm-5 col px-1 text-start text-sm-start">
                        <label htmlFor="amountInputs" className="label ">
                          Package Amount
                        </label>
                      </div>
                      <div className="col-sm-7 px-1 col-12">
                        <input
                          type="text"
                          className="form-control shadow-none me-auto w-100"
                          id="amountInput"
                          defaultValue={0}
                          value={Amount}
                        />
                      </div>
                    </div>
                    <br></br>
                    <div className="row mx-0 align-items-center justify-content-sm-between justify-content-center">
                      <div className="col-sm-5 col px-1 text-start text-sm-start">
                        <label htmlFor="USDT" className="label ">
                          USDT
                        </label>
                      </div>
                      <div className="col-sm-7 px-1 col-12">
                        <input
                          type="text"
                          className="form-control shadow-none me-auto w-100"
                          id="USDT"
                          value={UsdtAmount}
                          placeholder="Calculated USDT Value"
                          readOnly=""
                        />
                      </div>
                    </div>
                    <div className="row justify-content-center mx-0">
                      <div className="col-12 mt-3 text-center">
                        {/* Add an onclick event to each paymentadd button */}
                        <div
                          className="btn btn-theme1 text-white py-2 px-3 m-1 paymentadd"
                          onClick={() => CalculateValue()}
                        >
                           {IsUpgrade} $
                        </div>
                        
                      </div>
                      <div className="col-12 text-center py-3">
                        {/* Add an onclick event to the Reset button */}
                        <button
                          className="btn btn-theme1 py-2 px-5 restart fw-bold text-white"
                          onClick={() => reset()}
                        >
                          Reset
                        </button>{" "}
                        <a
                          className="btn btn-theme1 py-2 px-5 restart fw-bold text-white"
                          href="/dashboard"
                        >
                          Back To Home
                        </a>
                      </div>
                    </div>
                    <div className="row align-items-center justify-content-center mx-0">
                      <div className="col-sm-6 row align-items-center justify-content-center">
                        {loading == true ? (
                          <>
                            <Loader />
                          </>
                        ) : (
                          <>
                            {parseFloat(IsUpgrade) != 10 ? (
                              <button
                                className="btn btn-theme1 withdraw_btn w-100 py-sm-3 py-2 shadow-none fw-bold text-white"
                                onClick={() => Upgrade()}
                              >
                                Upgrade
                              </button>
                            ) : (
                              <button
                                className="btn btn-theme1 withdraw_btn w-100 py-sm-3 py-2 shadow-none fw-bold text-white"
                                onClick={() => Activate()}
                              >
                                Activate
                              </button>
                            )}
                          </>
                        )}
                      </div>
                      {/* <div className="col-12 ">
                        <p className="notetext">
                          Note: Activate/ Upgrade Amount Should Be Multiple of
                          $50.
                        </p>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <Footer />
        </div>
      </div>
      {/*---------------------------------------Dashboard Include----------------------------------------*/}
    </>
  );
}
