import React, { useState, useEffect } from "react";
import moment from "moment";

const Timer = ({ endTime }) => {
  const [remainingTime, setRemainingTime] = useState(null);
  const [timerEnd, setTimerEnd] = useState(false);

  useEffect(() => {
    const calculateTimeDifference = () => {
      if (!endTime) {
        return;
      }

      const currentTime = moment();
      const endDateTime = moment(endTime, "DD/MM/YYYY, hh:mm A");

      if (currentTime.isAfter(endDateTime)) {
        setTimerEnd(true);
        return;
      }

      const duration = moment.duration(endDateTime.diff(currentTime));
      const remaining = {
        days: duration.days(),
        hours: duration.hours(),
        minutes: duration.minutes(),
        seconds: duration.seconds(),
      };
      setRemainingTime(remaining);
    };

    if (endTime) {
      calculateTimeDifference();

      const interval = setInterval(calculateTimeDifference, 1000);
      return () => clearInterval(interval);
    }
  }, [endTime]);

  const formatTime = () => {
    if (!remainingTime) {
      return "";
    }
    const { days, hours, minutes, seconds } = remainingTime;
    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  };

  return (
    <div>
      <p style={{ color: "#fff", fontSize: "20px" }}>
        Back bonus Timer: {endTime ? (timerEnd === false ? formatTime() : "Qualify") : "0d 0h 0m 0s"}
      </p>
    </div>
  );
};

export default Timer;
