import React from "react";
import Footer from "../Footer/Footer";
import MobileNavbar from "../Navbar/MobileNavbar";
import Navbar from "../Navbar/Navbar";
import Header from "../Header/Header";

export default function AgcStakingBonus() {
  return (
    <>
      <Header />
      <Navbar />
      <MobileNavbar />
      <div className="container-fluid Activate_UpgradeSectiob tablecardSection">
        <div className="row">
          <div className="col-12">
            <div className="text-white fw-bold HeadingOtherpage py-2">
              Agc Staking Bonus
            </div>
          </div>
          <main className="col-md-12 mx-auto col-lg-12 col-12 px-md-4 mt-0">
            <div className="row mx-0 p-3">
              <div className="col-lg-12 mx-auto">
                <div className="card">
                  <div className="card-body ">
                    <div className="cardheading text-start">
                      Agc Staking Bonus
                    </div>
                    <div className="mt-md-3 table_main table-responsive">
                      <table className="table mb-0">
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>User ID</th>
                            <th>Form ID</th>
                            <th>On Amount $</th>
                            <th>Income $</th>
                            <th>Remark</th>
                            <th>Date &amp; Time</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>852469</td>
                            <td>172696</td>
                            <td>50.0000</td>
                            <td>0.1667</td>
                            <td>Activate Roi</td>
                            <td>14/02/2024, 12:01:32 AM</td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>852469</td>
                            <td>172696</td>
                            <td>50.0000</td>
                            <td>0.1667</td>
                            <td>Activate Roi</td>
                            <td>14/02/2024, 12:01:32 AM</td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td>852469</td>
                            <td>172696</td>
                            <td>50.0000</td>
                            <td>0.1667</td>
                            <td>Activate Roi</td>
                            <td>14/02/2024, 12:01:32 AM</td>
                          </tr>
                          <tr>
                            <td>4</td>
                            <td>852469</td>
                            <td>172696</td>
                            <td>50.0000</td>
                            <td>0.1667</td>
                            <td>Activate Roi</td>
                            <td>14/02/2024, 12:01:32 AM</td>
                          </tr>
                          <tr>
                            <td>5</td>
                            <td>852469</td>
                            <td>172696</td>
                            <td>50.0000</td>
                            <td>0.1667</td>
                            <td>Activate Roi</td>
                            <td>14/02/2024, 12:01:32 AM</td>
                          </tr>
                          <tr>
                            <td className="text-danger text-center" colSpan={7}>
                              No Record found !
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <Footer />
        </div>
      </div>
    </>
  );
}
