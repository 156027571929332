import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  aexnglobalAuth: false,
  userId: null,
  jwtToken: null,
  ipAddress: null,

};

export const AuthSlice = createSlice({
  name: "userAuth",
  initialState,
  reducers: {
    updateAuth: (state, action) => {
      state.aexnglobalAuth = action.payload.isAuth;
      state.userId = action.payload.userId;
      state.jwtToken = action.payload.jwtToken;
      state.ipAddress = action.payload.ipAddress;
    },
    updateStatus:(state,action)=>{
        state.status=action.payload.status
    }
  },
});

export const { updateAuth, updateStatus} = AuthSlice.actions;
export default AuthSlice.reducer;
