import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import AuthSlice from "./AuthSlice";
import persistStore from "redux-persist/es/persistStore";
import DashboardSlice from "./DashboardSlice";

const persistConfig = {
  key: "root",
  storage,
  version: -1,
};

const reducer = combineReducers({
  Auth: AuthSlice,
  Dashboarddata:DashboardSlice
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: false,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = () => {
  const persistor = persistStore(store);
  return persistor;
};
