import React from "react";
import { Link } from "react-router-dom";

import { updateAuth, updateStatus } from "../../Redux/AuthSlice";
import { useDispatch, useSelector } from "react-redux";

export default function Navbar() {
  const dispatch = useDispatch();

  function handleLogout() {
    dispatch(
      updateAuth({
        isAuth: false,
        userId: null,
        jwtToken: null,
        ipAddress: null,
      })
    );
  }

  const { userId, status } = useSelector((state) => state.Auth);

  return (
    <>
      {/*--------------------------------------------------Navbar Start--------------------------------*/}
      <nav
        className="navbar navbar-expand-xl nav-in"
        aria-label="Sixth  navbar example">
        <div className="container-fluid">
          <button
            className="navbar-toggler shadow-none border-0 d-none"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbarDark"
            aria-controls="offcanvasNavbarDark">
            <span className="navbar-toggler-icon" />
          </button>
          <div className="d-none d-xl-block">
            <ul className="navbar-nav mb-2 mb-md-0 gap-lg-2 gap-md-3 justify-content-center w-100">
              <li className="nav-item">
                <Link
                  className="nav-link active"
                  aria-current="page"
                  to="/dashboard">
                  Dashboard
                </Link>
              </li>

              <li className="nav-item dropdown" id="dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false">
                  Activation
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <Link className="dropdown-item" to="/ActivationUpgrade">
                      Activate / Upgrade
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/ActivationHistory">
                      Activate History
                    </Link>
                  </li>
                  {/* <li>
                    <Link className="dropdown-item" to="/MatrixActivationUpgrade">
                      Matrix Activate / Upgrade
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/MatrixActivationHistory">
                      Matrix Activate History
                    </Link>
                  </li> */}
                </ul>
              </li>
              <li className="nav-item dropdown" id="dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false">
                  All Income
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  {/* <li>
                    <Link className="dropdown-item" to="/stakingBonus">
                      ROI Income
                    </Link>
                  </li> */}
                  {/* <li>
                    <Link className="dropdown-item" to="/AutopoolIncome">
                      ROI Income
                    </Link>
                  </li> */}
                  <li>
                    <Link className="dropdown-item" to="/stakingGlobalteam">
                      Rapid Income
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/DirectIncomeStaking">
                      Direct Income
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/StakingLevelBonus">
                      Level Income
                    </Link>
                  </li>

                  <li>
                    <Link className="dropdown-item" to="/ReturnIncome">
                      Return Back Income
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/MatrixIncome">
                      Matrix Income
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/ctoincome">
                      CTO Income
                    </Link>
                  </li>

                  {/* <li>
                    <Link className="dropdown-item" to="/WithdrawalLevelIncome">
                      Withdrawal Level Income
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/SingleLegIncome">
                      Single Leg Income
                    </Link>
                  </li> */}
                </ul>
              </li>
              <li className="nav-item dropdown" id="dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false">
                  Team Details
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <Link className="dropdown-item" to="/myreferral">
                      My Referral
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/leveldetails">
                      Level Details
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/stakingdailybusiness">
                      My Downline
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/myteam">
                      Direct Leg Business
                    </Link>
                  </li>

                  <li>
                    <Link className="dropdown-item" to="/MatrixDownline">
                      Matrix Downline
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/TreeView">
                      Matrix Tree
                    </Link>
                  </li>
                  {/* <li>
                    <Link className="dropdown-item" to="/StakingTotalBusiness">
                    Staking Total Business Report
                    </Link>
                  </li> */}
                </ul>
              </li>
              <li className="nav-item dropdown" id="dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false">
                  Withdrawal
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <Link className="dropdown-item" to="/withdrawal">
                      Withdrawal
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/withdrawalhistory">
                      Withdrawal History
                    </Link>
                  </li>
                </ul>
              </li>
              {/* <li className="nav-item dropdown" id="dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false">
                  Convert Game Wallet
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <Link className="dropdown-item" to="/ConvertGameWallet_USDT_Staking">
                      Convert Game Wallet
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/GameWalletHistory">
                    Convert Game Wallet History
                    </Link>
                  </li>
                </ul>
              </li> */}
              <li className="nav-item dropdown" id="dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false">
                  Self Address
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <Link className="dropdown-item" to="/selfaddress">
                      Self Address
                    </Link>
                  </li>
                </ul>
              </li>
              {/* <li className="nav-item dropdown" id="dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Support
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <Link className="dropdown-item" to="/support">
                      Support
                    </Link>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="https://t.me/telegram"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Telegram Link
                    </a>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/newschannel">
                      News Channel{" "}
                    </Link>
                  </li>
                </ul>
              </li> */}
              <li className="nav-item">
                <a className="nav-link" onClick={() => handleLogout()}>
                  Logout
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {/*--------------------------------------------------Navbar End------------------------------------*/}
    </>
  );
}
