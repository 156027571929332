import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { loadWeb3 } from "../../Utility/contract";
import { API } from "../../Utility/API";
import { updateAuth } from "../../Redux/AuthSlice";

export default function Login() {
  let navigate = useNavigate();
  const [spinnerload, setspinnerload] = useState(false);
  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });
  const [LoginId, setLoginId] = useState("");
  const [connected, setconnected] = useState(true);
  const [ipAddress, setIP] = useState("");
  const dispatch = useDispatch();

  const walletConnected = async () => {
    try {
      let acc = await loadWeb3();
      let response = await axios.get("https://geolocation-db.com/json/");
      // console.log("response Geolocation", response.data.IPv4);
      setIP(response.data.IPv4);
      let ipaddress = response.data.IPv4;

      if (acc === "No Wallet" || acc === undefined) {
        toast.error("No Wallet");
        setconnected("Wallet is locked");
      } else if (acc === "Wrong Network") {
        toast.error(" Wrong Network Please Connect BSc Network");
        console.log("Wrong Network Please Connect BSC Scan Network");
        setconnected("Wrong Network");
      } else {
        // setaddress(acc)
        setconnected("Wallet is Connected");
        // setLoginId("0X484Demo848sdcssd48");
        setLoginId(acc);

        if (acc !== "") {
          let res = await API.post(`/userlogin`, {
            password: acc,
            ipaddress: ipaddress,
          });
          // console.log("UserloginNew Auto", res);
          let UserID = res.data.data;
          if (res.data.data.result === "Successfull") {
            toast.success(`Login Successful`);
            // console.log("loginmask", res.data.token);
            dispatch(
              updateAuth({
                isAuth: true,
                userId: res.data.data.uid_output,
                jwtToken: res.data.token,
                ipAddress: ipaddress,
              })
            );
            navigate("/dashboard");
          } else {
            toast.error(res.data.data.result);
          }
        }
      }
    } catch (e) {
      console.log("Error While WalletConnect", e);
    }
  };

  const onSubmitHandler = async (data) => {
    // console.log("LoginId", LoginId);
    let acc = await loadWeb3();

    setspinnerload(true);
    let response = await axios.get("https://geolocation-db.com/json/");
    console.log("response Geolocation", response.data.IPv4);
    setIP(response.data.IPv4);
    let ipaddress = response.data.IPv4;
    let res = await API.post(`/login`, {
      uid: LoginId,
      ipaddress: ipaddress,
    });

    console.log("auth", res.data);
    if (res.data.data.result === "Successfull") {
      toast.success(res.data.data.result);
      // console.log("login" , res );
      dispatch(
        updateAuth({
          isAuth: true,
          userId: res.data.data.outuid,
          jwtToken: res.data.token,
          ipAddress: ipaddress,
        })
      );
      navigate("/dashboard");
    } else {
      toast.error(`Invalid User id or Address`);
      setspinnerload(false);
    }
    setspinnerload(false);
  };

  useEffect(() => {
    walletConnected();
  }, []);

  return (
    <>
      <div className="container-fluid loginpage vh-100">
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-5 h-100">
              <div className="card border-theme1">
                <div className="card-body">
                  <div className="row mx-0 align-items-center justify-content-center gy-3">
                    <div className="col-12">
                      <div className="logoimage mx-auto">
                        <img
                          className="w-100 h-100"
                          src="./assets/image/logo.png"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-12 text-center">
                      <div className="loginheading">
                        Access Your Personal Account
                      </div>
                      <div className="subtext">Wallet is Locked</div>
                    </div>
                    <div className="col-12 text-center">
                      {/* remove this link tag on time of integration */}
                      {/* <Link to="/dashboard"> */}
                      <button
                        className="btn btn-theme1 btnlogin"
                        onClick={() => {
                          onSubmitHandler();
                        }}
                      >
                        Authorized Login
                      </button>
                      {/* </Link> */}
                    </div>
                    <div className="col-12">
                      <div className="remembertext">
                        Remember to authorize with the correct address.View an
                        Account
                      </div>
                    </div>
                    <div className="col-12">
                      <input
                        type="text"
                        placeholder="Please Enter ID or Address"
                        className="control-form"
                        value={LoginId}
                        onChange={(e) => setLoginId(e.target.value)}
                      />
                    </div>
                    <div className="col-12">
                      <div className="remembertext">
                        Enter the account id or BNB Address
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="remembertext2">
                        Don’t have an account?{" "}
                        <Link to="/register"> Register</Link>
                      </div>
                      <div className="remembertext2">
                        Copyright © 2024. All Rights Reserved.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
