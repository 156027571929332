import React from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Navbar from "../Navbar/Navbar";
import MobileNavbar from "../Navbar/MobileNavbar";

export default function DailyBusiness() {
  return (
    <>
      <Header />
      <Navbar />
      <MobileNavbar />
      <div className="container-fluid  tablecardSection">
        <div className="row">
          <div className="col-12">
            <div className="text-white fw-bold HeadingOtherpage py-sm-4 py-2">
            Staking Daily Business Report
            </div>
          </div>
          <main className="col-12 px-md-3 mt-0">
            <div className="card">
              <div className="card-body">
                <div className="mt-md-3 table_main table-responsive">
                  <table className="table mb-0">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>User ID</th>
                        <th>Self Investment</th>
                        <th>Team Bussines</th>
                        <th>Total Bussines</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>12345</td>
                        <td>10000</td>
                        <td>Team Bussines</td>
                        <td>Total Bussines</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>12345</td>
                        <td>10000</td>
                        <td>Team Bussines</td>
                        <td>Total Bussines</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>12345</td>
                        <td>10000</td>
                        <td>Team Bussines</td>
                        <td>Total Bussines</td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>12345</td>
                        <td>10000</td>
                        <td>Team Bussines</td>
                        <td>Total Bussines</td>
                      </tr>
                      <tr>
                        <td>5</td>
                        <td>12345</td>
                        <td>10000</td>
                        <td>Team Bussines</td>
                        <td>Total Bussines</td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="pagination ms-2 mt-3">
                    <ul> {/*pages or li are comes from javascript */} </ul>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <Footer />
        </div>
      </div>
    </>
  );
}
