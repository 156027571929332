import React from "react";

export default function Footer() {
  return (
    <>
      {/* footer Include Start  */}
      <footer className="footer bg-theme2 mt-3" >
        <p className="mb-0 text-white py-2 text-center">
          Copyright © Strivepro.live 2024. All right reserved.
        </p>
      </footer>
      {/* footer End Start  */}
    </>
  );
}
