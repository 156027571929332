import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const DashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    fetchUserInfoSuccess: (state, action) => {
       return action.payload
    },
  },
});

export const { fetchUserInfoSuccess } = DashboardSlice.actions;
export default DashboardSlice.reducer;
