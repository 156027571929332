import React, { useState, useEffect } from "react";
import { Offcanvas } from "bootstrap";
import { Link } from "react-router-dom";

import { updateAuth, updateStatus } from "../../Redux/AuthSlice";
import { useDispatch, useSelector } from "react-redux";

export default function MobileNavbar() {
  const [offcanvasInstance, setOffcanvasInstance] = useState(null);

  const dispatch = useDispatch();

  function handleLogout() {
    dispatch(
      updateAuth({
        isAuth: false,
        userId: null,
        jwtToken: null,
        ipAddress: null,
      })
    );
  }

  const { userId, status } = useSelector((state) => state.Auth);

  useEffect(() => {
    const offcanvasElement = document.getElementById("offcanvasNavbarDark");
    const newOffcanvasInstance = new Offcanvas(offcanvasElement);
    setOffcanvasInstance(newOffcanvasInstance);

    return () => {
      if (newOffcanvasInstance) {
        newOffcanvasInstance.dispose();
      }
    };
  }, []);

  const handleToggleOffcanvas = () => {
    if (offcanvasInstance) {
      offcanvasInstance.toggle();
    }
  };
  return (
    <>
      {/*--------------------------------------------------Offcanvas Start-------------------------------*/}

      {/* Offcanvas container */}
      <div
        className="offcanvas offcanvas-start"
        tabIndex={-1}
        id="offcanvasNavbarDark"
        aria-labelledby="offcanvasNavbarDarkLabel"
        aria-modal="true"
        role="dialog"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasNavbarDarkLabel">
            <img className="w-100 h-100" src="./assets/image/logo.png" alt="" />
          </h5>
          <button
            type="button"
            className="btn-close btn-close-white shadow-none border-0"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          />
        </div>
        <div className="offcanvas-body">
          <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
            <li className="nav-item">
              <Link
                className="nav-link active"
                aria-current="page"
                to="/dashboard"
              >
                Dashboard
              </Link>
            </li>
            <li className="nav-item dropdown" id="dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
              >
                 Activation
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <Link className="dropdown-item" to="/ActivationUpgrade">
                    Activate / Upgrade
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/ActivationHistory">
                    Activate History
                  </Link>
                </li>
                {/* <li>
                    <Link className="dropdown-item" to="/MatrixActivationUpgrade">
                      Matrix Activate / Upgrade
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/MatrixActivationHistory">
                      Matrix Activate History
                    </Link>
                  </li> */}
              </ul>
            </li>
            <li className="nav-item dropdown" id="dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false">
                  All Income
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  {/* <li>
                    <Link className="dropdown-item" to="/stakingBonus">
                     ROI Income
                    </Link>
                  </li> */}
                  <li>
                    <Link className="dropdown-item" to="/stakingLevelBonus">
                      Referral Bonus
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/stakingTeamFounder">
                     Return Back Bonus
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/stakingGlobalteam">
                     Community Bonus
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/stakingGlobalteam">
                      Royality Club Bonus
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/stakingGlobalteam">
                    Matrix Level Income
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/ctoincome">
                      CTO Income
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown" id="dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false">
                  Team Details
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <Link className="dropdown-item" to="/TreeView">
                      Matrix Tree
                    </Link>
                    <li>
                    <Link className="dropdown-item" to="/stakingdailybusiness">
                    My Downline
                    </Link>
                  </li>
                    <Link className="dropdown-item" to="/myreferral">
                      My Referral
                    </Link>
                  </li>
                  {/* <li>
                    <Link className="dropdown-item" to="/myteam">
                      My Team
                    </Link>
                  </li> */}
                  <li>
                    <Link className="dropdown-item" to="/leveldetails">
                      Level Details
                    </Link>
                  </li>
                 
                  {/* <li>
                    <Link className="dropdown-item" to="/StakingTotalBusiness">
                    Staking Total Business Report
                    </Link>
                  </li> */}
                  
                </ul>
              </li>
            <li className="nav-item dropdown" id="dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Withdrawal
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <Link className="dropdown-item" to="/withdrawal">
                    Withdrawal
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/withdrawalhistory">
                    Withdrawal History
                  </Link>
                </li>
              </ul>
            </li>
           
            <li className="nav-item dropdown" id="dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Self Address
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <Link className="dropdown-item" to="/selfaddress">
                    Self Address
                  </Link>
                </li>
              </ul>
            </li>
            {/* <li className="nav-item dropdown" id="dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Support
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <Link className="dropdown-item" to="/support">
                    Support
                  </Link>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="https://t.me/telegram"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Telegram Link
                  </a>
                </li>
                <li>
                  <Link className="dropdown-item" to="/newschannel">
                    News Channel{" "}
                  </Link>
                </li>
              </ul>
            </li> */}
            <li className="nav-item">
              <a className="nav-link" onClick={() => handleLogout()}>
                Logout
              </a>
            </li>
          </ul>
        </div>
      </div>
      {/*--------------------------------------------------Offcanvas End---------------------------------*/}
    </>
  );
}
