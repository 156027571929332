import React, { useEffect, useState } from 'react'
import Header from '../Header/Header'
import Navbar from '../Navbar/Navbar'
import MobileNavbar from '../Navbar/MobileNavbar'
import { API } from '../../Utility/API';
import { useDispatch, useSelector } from 'react-redux';
import { updateAuth } from '../../Redux/AuthSlice';
import Table_Buttons from '../Table_Buttons/Table_Button';
import Table from '../Table/Table';
import Footer from '../Footer/Footer';

export default function CtoIncome() {

    const [ReportApi, setReportApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(50);
  const [totalCount, settotalCount] = useState(50);
  const dispatch = useDispatch();

  const user = useSelector((state) => state.Auth.userId);
  const token = useSelector((state) => state.Auth.jwtToken);

  const Report_API = async (rank) => {
    try {
      let responce = await API.post(
        "ctoIncome",
        {
          uid: user,
          fdate: "",
          tdate: "",
        },
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json", 
          },
        }
      );
      responce = responce.data.data[0];
      console.log("CTOres-->", responce);
      setReportApi([]);

      let arr = [];
      responce.forEach((item, index) => {
        arr.push({
          sr: item.row,
          uid: item.uid,
          dd: item.edate,
          ctoamount: item.ctoamount,
          ctorate:item.ctorate,
          income:item.income
        });
      });

      setReportApi(arr);
    } catch (e) {
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            isAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
      }
    }
  };

  useEffect(() => {
    Report_API();
  }, []);

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = ReportApi.slice(indexOfFirstPage, indexOfLastPost);

  var [reportData, set_reportData] = new useState({
    cols: [
      { Header: "S.No", accessor: "sr" },
      { Header: "User Id", accessor: "uid" },
      // { Header: "Income $", accessor: "income" },
      // { Header: "CTO Amount", accessor: "ctoamount" },
      { Header: "CTO Rate", accessor: "ctorate" },
      { Header: "Income", accessor: "income" },
      { Header: "Date", accessor: "dd" },
    ],
  });

  return (
    <>
      <Header />
      <Navbar />
      <MobileNavbar />
      <div className="container-fluid  tablecardSection">
        <div className="row">
          <div className="col-12">
            <div className="text-white fw-bold HeadingOtherpage py-sm-4 py-2">
              CTO Income
            </div>
          </div>

          <main className="col-12 px-md-3 mt-0">
            <div className="card">
              <div className="card-body">
                <div className="mt-md-3 table_main table-responsive">
                  <Table data={[...ReportApi]} columns={reportData.cols} />
                  <Table_Buttons
                    indexOfFirstPage={indexOfFirstPage}
                    indexOfLastPost={indexOfLastPost}
                    setcurrentPage={setcurrentPage}
                    currentPage={currentPage}
                    totalData={totalCount}
                    listPerpage={listPerpage}
                    handleRowsPerPageChange={setlistPerpage}
                  />
                </div>
              </div>
            </div>
          </main>
          <Footer />
        </div>
      </div>
    </>
  )
}
