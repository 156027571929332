import React, { useEffect, useState } from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Navbar from "../Navbar/Navbar";
import MobileNavbar from "../Navbar/MobileNavbar";


import Table from "../Table/Table";
import Table_Buttons from "../Table_Buttons/Table_Button";
import { API } from "../../Utility/API";
import { updateAuth, updateStatus } from "../../Redux/AuthSlice";
import { useDispatch, useSelector } from "react-redux";

export default function StakingTeamFounder() {

  
  const [ReportApi, setReportApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(50);
  const [totalCount, settotalCount] = useState(50);
  const [userid, setUserId] = useState("");
  const dispatch = useDispatch();

  const user = useSelector((state) => state.Auth.userId);
  const token = useSelector((state) => state.Auth.jwtToken);

  const Report_API = async () => {
    try {

      let responce = await API.post(
        "StakingReferralBonus",
        {
          uid: user
        },
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json", 
          },
        }
      );
      responce = responce.data.data;
      console.log("res-->", responce);
      setReportApi([]);

      let arr = [];
      responce.forEach((item, index) => {
        arr.push({
          sr: index + 1,
          uid: item.uid,
          fromid: item.id,
          onamount: item.onamount,
          income: item.Level_income,
          dd: item.dd,
          level: item.lvl,
        });
      });

      setReportApi(arr);
    } catch (e) {
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            isAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
      }
    }
  };

  useEffect(() => {
    Report_API();
  }, []);

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = ReportApi.slice(indexOfFirstPage, indexOfLastPost);

  var [reportData, set_reportData] = new useState({
    cols: [
      { Header: "S.No", accessor: "sr" },
      { Header: "User Id", accessor: "uid" },
      { Header: "From Id", accessor: "fromid" },
      { Header: "On Amount $", accessor: "onamount" },
      { Header: "Income $", accessor: "income" },
      { Header: "Level", accessor: "level" },
      { Header: "Date", accessor: "dd" },
    ],
  });




  return (
    <>
      <Header />
      <Navbar />
      <MobileNavbar />
      <div className="container-fluid  tablecardSection">
        <div className="row">
          <div className="col-12">
            <div className="text-white fw-bold HeadingOtherpage py-sm-4 py-2">
              Return Back Bonus
            </div>
          </div>
          {/* <div className="row mx-0 align-items-center g-3 mb-sm-4 mb-3">
            <div className="col-md-3">
              <label className="text-white fs-12 mb-0">Enter From Date</label>
              <input
                type="date"
                name="from_date"
                id="from_date"
                className="form-control border-0 text-white bg-theme1 shadow-none"
                placeholder="Enter From Date"
              />
            </div>
            <div className="col-md-3">
              <label className="text-white fs-12 mb-0">Enter To Date</label>
              <input
                type="date"
                name="to_date"
                id="to_date"
                className="form-control border-0 text-white bg-theme1 shadow-none"
              />
            </div>
            <div className="col-md-auto col-sm-4 col-12">
              <input
                type="submit"
                name="to_date"
                defaultValue="Search"
                className="btn btn-danger bg-theme1 mt-4 border-0 px-3 shadow-none rounded-3"
              />
            </div>
            <div className="col-md-auto col-sm-4 col-12">
              <div className="totalAmoutbnt text-white mt-4">Total Amount</div>
            </div>
            <div className="col-md-auto col-sm-auto col-12 mt-0">
              <input
                type="text"
                defaultValue={0.0}
                className="form-control shadow-none bg-transparent w-auto border-theme1 text-white mt-lg-5 mt-3 mt-md-5 "
              />
            </div>
          </div> */}
          <main className="col-12 px-md-3 mt-0">
            <div className="card">
              <div className="card-body">
                <div className="mt-md-3 table_main table-responsive">
                  
                <Table data={[...ReportApi]} columns={reportData.cols} />
                  <Table_Buttons
                    indexOfFirstPage={indexOfFirstPage}
                    indexOfLastPost={indexOfLastPost}
                    setcurrentPage={setcurrentPage}
                    currentPage={currentPage}
                    totalData={totalCount}
                    listPerpage={listPerpage}
                    handleRowsPerPageChange={setlistPerpage}
                  />

                </div>
              </div>
            </div>
          </main>
          <Footer />
        </div>
      </div>
    </>
  );
}
