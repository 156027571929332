import { useSelector } from "react-redux";
import { Outlet, Navigate } from "react-router-dom";

const Privateroutes = () => {
  const { aexnglobalAuth, jwtToken, ipAddress } = useSelector(
    (state) => state.Auth
  );

  return (
    <>
      {aexnglobalAuth === true && jwtToken.length > 20 && ipAddress ? (
        <Outlet />
      ) : (
        <Navigate to="/" />
      )}
    </>
  );
};

export default Privateroutes;
