import React, { useEffect, useState } from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Navbar from "../Navbar/Navbar";
import MobileNavbar from "../Navbar/MobileNavbar";
import Table from "../Table/Table";
import Table_Buttons from "../Table_Buttons/Table_Button";
import { updateAuth } from "../../Redux/AuthSlice";
import { API } from "../../Utility/API";
import { useDispatch, useSelector } from "react-redux";

export default function GameWalletHistory() {
  const [ReportApi, setReportApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(50);
  const [totalCount, settotalCount] = useState(50);
  const [userid, setUserId] = useState("");
  const dispatch = useDispatch();

  const user = useSelector((state) => state.Auth.userId);
  const token = useSelector((state) => state.Auth.jwtToken);

  const Report_API = async () => {
    try {
      let responce = await API.post(
        "StakingWalletToGameWalletHistory",
        {
          uid: user,
        },
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json", 
          },
        }
      );
      responce = responce.data.data[0];
      console.log("res-->", responce);
      setReportApi([]);

      let arr = [];
      responce.forEach((item, index) => {
        arr.push({
          sr: index + 1,
          uid: item.uid,
          request_amount: parseFloat(item.debit).toFixed(4),
          adminchargerate: `${item?.admincharge} $`,
          
          token: `${item.token} PTS`,
         
          date: item.date,
        });
      });

      setReportApi(arr);
    } catch (e) {
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            isAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
      }
    }
  };

  useEffect(() => {
    Report_API();
  }, []);

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = ReportApi.slice(indexOfFirstPage, indexOfLastPost);

  var [reportData, set_reportData] = new useState({
    cols: [
      { Header: "S.No", accessor: "sr" },
      { Header: "User ID", accessor: "uid" },
      { Header: "Request Amount $", accessor: "request_amount" },
      { Header: "Admin Charge ", accessor: "adminchargerate" },
      { Header: "PTS Value", accessor: "token" },
      { Header: "Request Date", accessor: "date" },
    
    ],
  });
  return (
    <>
      <Header />
      <Navbar />
      <MobileNavbar />
      <div className="container-fluid  tablecardSection">
        <div className="row">
          <div className="col-12">
            <div className="text-white fw-bold HeadingOtherpage py-sm-4 py-2">
              Convert Game Wallet History
            </div>
          </div>
          <main className="col-12 px-md-3 mt-0">
            <div className="card">
              <div className="card-body">
                <div className="mt-md-3 table_main table-responsive">
                  <Table data={[...currentPost]} columns={reportData.cols} />

                  <Table_Buttons
                    indexOfFirstPage={indexOfFirstPage}
                    indexOfLastPost={indexOfLastPost}
                    setcurrentPage={setcurrentPage}
                    currentPage={currentPage}
                    totalData={ReportApi.length}
                    listPerpage={listPerpage}
                    handleRowsPerPageChange={setlistPerpage}
                  />
                </div>
              </div>
            </div>
          </main>
          <Footer />
        </div>
      </div>
    </>
  );
}
