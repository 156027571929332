import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { API } from "../../Utility/API";
import { updateAuth, updateStatus } from "../../Redux/AuthSlice";
import { useDispatch, useSelector } from "react-redux";
import { loadWeb3 } from "../../Utility/contract";
import axios from "axios";
import DOMPurify from "dompurify";

export default function Registration() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [sponser, setSponser] = useState(searchParams.get("userid"));
  const [selectPosition, setselectPosition] = useState(
    searchParams.get("position") === "Left"
      ? 1
      : searchParams.get("position") === "Right"
      ? 2
      : ""
  );

  let navigate = useNavigate();
  const [registor_Model, setregistor_Model] = useState(false);
  const [connected, setconnected] = useState(true);
  const [sid, setSid] = useState(sponser);
  const [walletAddress, setWalletAddress] = useState("");
  const [LoginId, setLoginId] = useState();

  // console.log("sid", sid);
  const dispatch = useDispatch();

  const walletConnected = async () => {
    try {
      let acc = await loadWeb3();
      let response = await axios.get("https://geolocation-db.com/json/");
      console.log("response Geolocation", response.data.IPv4);
      // setIP(response.data.IPv4);
      let ipaddress = response.data.IPv4;

      if (acc === "No Wallet" || acc === undefined) {
        toast.error("No Wallet");
        setconnected("Wallet is locked");
      } else if (acc === "Wrong Network") {
        toast.error(" Wrong Network Please Connect BSc Network");
        console.log("Wrong Network Please Connect BSC Scan Network");
        setconnected("Wrong Network");
      } else {
        // setaddress(acc)
        setconnected("Wallet is Connected");
        // setLoginId("0X484Demo848sdcssd48");
        setLoginId(acc);
        let res = await API.post(`/login`, {
          uid: acc,
          ipaddress: ipaddress,
        });
        let UserID = res.data.data;
        if (res.data.data.result === "Successfull") {
          toast.success(`Login Successful`);
          dispatch(
            updateAuth({
              isAuth: true,
              userId: res.data.data.outuid,
              jwtToken: res.data.token,
              ipAddress: ipaddress,
            })
          );
          navigate("/dashboard");
        } else {
          toast.error(res.data.data.result);
        }
      }
    } catch (e) {
      console.log("Error While WalletConnect", e);
    }
  };

  const registerUser = async () => {
    let acc = await loadWeb3();
    let response = await axios.get("https://geolocation-db.com/json/");
    console.log("response Geolocation", response.data.IPv4);
    // setIP(response.data.IPv4);
    let ipaddress = response.data.IPv4;
    if (!acc) {
      setconnected("Wallet is locked");
    } else if (acc == "Wrong Network") {
      toast.error(" Wrong Network Please Connect BSc Network");
      setconnected("Wrong Network");
    } else {
      try {
        if (sid === "" || sid === undefined) {
          toast.error("Enter Upline Id");
        } else {
          let res = await API.post("registration", {
            sid: sid,
            walletAddress: acc,
            psw: acc,
            position: 1,
          });
          console.log("registration", res.data);
          if (res.data.data.result === "Successfull") {
            toast.success("Register Successfull");
            let ress = await API.post(`/login`, {
              uid: acc,
              ipaddress: ipaddress,
            });

            dispatch(
              updateAuth({
                isAuth: true,
                userId: ress.data.data.outuid,
                jwtToken: ress.data.token,
                ipAddress: ipaddress,
              })
            );
            navigate("/DashBoard");
          } else {
            toast.error(`${res.data.data.result}`);
          }
        }
      } catch (e) {
        console.log("Error while regiater", e);
      }
    }
  };

  useEffect(() => {
    walletConnected();
  }, []);

  return (
    <>
      <div className="container-fluid loginpage vh-100">
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-5 h-100">
              <div className="card border-theme1">
                <div className="card-body">
                  <div className="row mx-0 align-items-center justify-content-center gy-3">
                    <div className="col-12">
                      <div className="logoimage mx-auto">
                        <img
                          className="w-100 h-100"
                          src="./assets/image/logo.png"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-12 text-center">
                      <div className="loginheading">
                        Access Your Personal Account
                      </div>
                      <div className="subtext">Wallet is Locked</div>
                    </div>
                    <div className="col-12 text-center">
                      <button
                        onClick={() => setregistor_Model(true)}
                        className="btn btn-theme1 btnlogin"
                      >
                        Register
                      </button>
                    </div>
                    <div className="col-12">
                      <div className="remembertext">
                        Remember to authorize with the correct address.View an
                        Account
                      </div>
                    </div>
                    <div className="col-12">
                      <input
                        type="text"
                        placeholder="Please Enter ID or Address"
                        className="control-form"
                        value={LoginId}
                        readOnly
                      />
                    </div>
                    <div className="col-12 text-center">
                      <button className="btn btn-theme1 btnlogin">
                        Connect to Wallet
                      </button>
                    </div>
                    <div className="col-12">
                      <div className="remembertext">
                        Enter the account id or BNB Address
                      </div>
                    </div>
                    <div
                      id="myModal"
                      className="modal"
                      style={{ display: registor_Model ? "block" : "none" }}
                    >
                      <div
                        className="modal-content-register boxset"
                        style={{
                          background: "rgb(0 95 203)",
                          padding: "20px",
                          borderRadius: "17px",
                          boxShadow: "0px 0px 5px 5px #000",
                          marginTop: "124px",
                        }}
                      >
                        <h4 className="text-white">Enter Upline Id</h4>
                        <br />
                        <p>
                          {" "}
                          <input
                            id="txtsid"
                            // className="input-id"
                            className="control-form"
                            value={sid}
                            maxLength={8}
                            type="text"
                            min={0}
                            placeholder="Enter Upline Id"
                            onChange={(e) => {
                              const inputText = e.target.value
                                .replace(/[^0-9]/gi, "")
                                .substring(0, 8);
                              const sanitizedInput =
                                DOMPurify.sanitize(inputText);
                              setSid(sanitizedInput);
                            }}
                          />
                        </p>
                        {/* <select
                          className="form-control"
                          id="__BVID__17"
                          value={selectPosition}
                          onChange={(e) => setselectPosition(e.target.value)}
                        >
                          <option selected>Please Select Position</option>
                          <option value="1">Left</option>
                          <option value="2">Right</option>
                        </select> */}
                        <br />
                        <div
                          className="rerlbtn"
                          style={{ textAlign: "center" }}
                        >
                          <button
                            className="btn btn_Model btnlogin btn-success"
                            onClick={() => {
                              registerUser();
                            }}
                          >
                            Register
                          </button>

                          <button
                            className="mx-2 btn_Model btn btnlogin btn-danger"
                            style={{ background: "red" }}
                            onClick={() => setregistor_Model(false)}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="remembertext2">
                        If you have already registered then{" "}
                        <Link to="/login">Login</Link>
                      </div>
                      <div className="remembertext2">
                        Copyright © 2024. All Rights Reserved.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
