import React, { useEffect, useState } from "react";
import Footer from "../Footer/Footer";
import MobileNavbar from "../Navbar/MobileNavbar";
import Navbar from "../Navbar/Navbar";
import Header from "../Header/Header";
import { useDispatch, useSelector } from "react-redux";
import DOMPurify from "dompurify";
import { API } from "../../Utility/API";
import { updateAuth } from "../../Redux/AuthSlice";
import { useNavigate } from "react-router-dom";
import { loadWeb3 } from "../../Utility/contract";
import { toast } from "react-toastify";

export default function Withdrawal() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { Net_Balance, Accountaddress, top_up } = useSelector(
    (state) => state.Dashboarddata
  );
  const user = useSelector((state) => state.Auth.userId);

  const sec = useSelector((state) => state.Auth.jwtToken);

  const [amount, setAmount] = useState("");
  const [token, setToken] = useState("");
  const [connected, setConnected] = useState("Wallet is locked");
  const handleAmount = (e) => {
    const value1 = DOMPurify.sanitize(
      e.target.value.replace(/[^0-9]/gi, "").substring(0, 6)
    );
    setAmount(value1);
    // setAmount((prev) => {
    //   if (prev !== value1) {
    //     calculateToken(value1);
    //     return value1;
    //   }
    //   return prev;
    // });
  };

  const calculateToken = async (value) => {
    try {
      let res = await API.post(
        "/GetTokenValue",
        {
          amount: value,
        },
        {
          headers: {
            Authorization: `${sec}`,
            "Content-Type": "application/json",
          },
        }
      );
      // console.log("token res", res);
      setToken(res.data.data[0][0].TokenAmount);
    } catch (e) {
      console.log("TOken API error", e);
      dispatch(
        updateAuth({
          isAuth: false,
          userId: null,
          jwtToken: null,
          ipAddress: null,
        })
      );
      navigate("/");
    }
  };

  const WalletConnected = async () => {
    try {
      const acc = await loadWeb3();
      if (acc === "No Wallet") {
        setConnected("Wallet is locked");
      } else if (acc === "Wrong Network") {
        toast.error("Wrong Network. Please Connect BSC Network");
        setConnected("Wrong Network");
      } else {
        setConnected("Wallet is Connected");
      }
    } catch (error) {
      console.error("Error while connecting wallet:", error);
    }
  };

  const Withdrawal_API = async () => {
    try {
      const acc = await loadWeb3();
      // console.log("acccc", acc);
      if (acc === "No Wallet") {
        toast.error("No Wallet");
        return;
      }

      if (acc === "Wrong Network") {
        toast.error("Wrong Network. Please Connect Polygon Scan Network");
        return;
      }

      if (acc.toUpperCase() !== Accountaddress.toUpperCase()) {
        toast.error("Wallet address does not match");
        return;
      }

      if (amount <= 0) {
        toast.error("Invalid amount");
        return;
      }

      const res = await API.post(
        "/withdrawal",
        {
          uid: user,
          amount: amount,
        },
        {
          headers: {
            Authorization: `${sec}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Withdrawal API", res.data.data);
      if (res.data.data === "SUCCESSFUL") {
        toast.success("Withdrawal Successfull");
        navigate("/withdrawalhistory");
      } else {
        toast.error(res.data.data);
      }
    } catch (error) {
      console.error("Error while withdrawing:", error);
      dispatch(
        updateAuth({
          isAuth: false,
          userId: null,
          jwtToken: null,
          ipAddress: null,
        })
      );
      navigate("/");
    }
  };

  useEffect(() => {
    WalletConnected();
  }, []);

  return (
    <>
      <Header />
      <Navbar />
      <MobileNavbar />
      <div className="container-fluid Activate_UpgradeSectiob">
        <div className="row">
          <div className="col-12">
            <div className="text-white fw-bold HeadingOtherpage py-2">
              Withdrawal
            </div>
          </div>
          <main className="col-md-9 mx-auto col-lg-10 col-12 px-md-4 mt-0">
            <div className="row mx-0 p-3">
              <div className="col-lg-7 mx-auto">
                <div className="card py-4">
                  <div className="card-body">
                    <div className="cardheading">Withdrawal</div>
                    <p className="textimportant">{connected} </p>
                    <div className="row mx-0 align-items-center justify-content-between">
                      <div className="col-md-6 px-1">
                        <label className="label fs-6" htmlhtmlFor="amountInput0">
                          Wallet Net USD Value
                        </label>
                      </div>
                      <div className="col-md-6 px-1">
                        <input
                          type="text"
                          className="form-control shadow-none w-100 text-black border-theme1"
                          id="amountInput0"
                          defaultValue={Net_Balance}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="row mx-0 align-items-center justify-content-between my-3">
                      <div className="col-md-6 px-1">
                        <label className="label fs-6" htmlhtmlFor="amountInput1">
                          Enter USD Amount
                        </label>
                      </div>
                      <div className="col-md-6 px-1">
                        <input
                          type="text"
                          className="form-control shadow-none w-100 text-black border-theme1"
                          id="amountInput1"
                          placeholder="Enter USD Amount"
                          value={amount}
                          onChange={handleAmount}
                        />
                      </div>
                    </div>

                    {parseInt(top_up) === 1 && (
                      <div className="row align-items-center justify-content-center mx-0 pt-4">
                        <div className="col-sm-6 row align-items-center justify-content-center">
                          <button
                            className="btn btn-theme1 withdraw_btn w-100 py-sm-3 py-2 shadow-none"
                            onClick={() => Withdrawal_API()}>
                            Withdrawal
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </main>
          <Footer />
        </div>
      </div>
    </>
  );
}
