import React from "react";
import { Link } from "react-router-dom";

import { updateAuth } from "../../Redux/AuthSlice";
import { useDispatch, useSelector } from "react-redux";

export default function Header() {
  const dispatch = useDispatch();
  function handleLogout() {
    dispatch(
      updateAuth({
        isAuth: false,
        userId: null,
        jwtToken: null,
        ipAddress: null,
      })
    );
  }

  const { userId,} = useSelector((state) => state.Auth);

  return (
    <>
      {/*-------------------------------------------------header Include Start-------------------------*/}
      <div className="container-fluid topHeaderSection px-md-4 py-2 ">
        <div className="row align-items-center">
          <div className="col">
            <Link to="/dashboard" className="logotop d-flex">
              <img
                className="w-100 h-100"
                src="./assets/image/logo.png"
                alt=""
              />
            </Link>
          </div>
          <div className="col-auto d-flex align-items-center gap-3">
            <a onClick={() => handleLogout()} className="logoouticon">
              <img
                className="w-100 h-100"
                src="./assets/image/power-off.svg"
                alt="power-off"
              />
            </a>
            <button
              className="navbar-toggler shadow-none border-0 d-block d-xl-none"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbarDark"
              aria-controls="offcanvasNavbarDark"
            >
              <span>
                <img
                  className="w-100 h-100"
                  src="./assets/image/bars.svg"
                  alt="bars"
                />
              </span>
            </button>
          </div>
        </div>
      </div>
      {/*--------------------------------------------------header Include end------------------------*/}
    </>
  );
}
