import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import Navbar from "../Navbar/Navbar";
import MobileNavbar from "../Navbar/MobileNavbar";
import Footer from "../Footer/Footer";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { API } from "../../Utility/API";
import { updateAuth } from "../../Redux/AuthSlice";
import { useDispatch, useSelector } from "react-redux";
import { loadWeb3 } from "../../Utility/contract";

import {
  Abi_Main,
  Abi_Token,
  Abi_USDT,
  ContractAddress_Main,
  ContractAddress_Token,
  ContractAddress_USDT,
} from "../../Utility/ContractAddress";
import { fetchUserInfoSuccess } from "../../Redux/DashboardSlice";
import Timer from "../Timer/Timer";

export default function UserDashboard() {
  const user = useSelector((state) => state.Auth.userId);
  const sec = useSelector((state) => state.Auth.jwtToken);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let URL = window.location.origin;
  const CopyReferralLink = () => {
    const text = `${URL}/register?userid=${user}`;
    //  `${URL}/registration?userid=${user}`
    navigator.clipboard.writeText(text);
    toast.success("Copied");
  };

  const [dash, setdataArray] = useState([]);
  const [liveRate, setLiveRate] = useState("");
  const [walletAddress, setwalletAddress] = useState("ConnectWallet");

  const Dashboard_API = async () => {
    try {
      let acc = await loadWeb3();
      setwalletAddress(acc);
      let responce = await API?.get(`userDashboard?uid=${user}`, {
        headers: {
          Authorization: `${sec}`,
          "Content-Type": "application/json",
        },
      });
      responce = responce.data.data[0][0];

      console.log("Dash res-->", responce);

      setdataArray(responce);
      dispatch(fetchUserInfoSuccess(responce));
    } catch (e) {
      console.log("Error While calling dashboard API", e);
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            isAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
        navigate("/");
      }
    }
  };

  useEffect(() => {
    Dashboard_API();
    
  }, []);

  return (
    <>
      <>
        <Header />
        <Navbar />
        <MobileNavbar />
        {/*----------------------------------------Dashboard Include---------------------------------------*/}
        <div className="container-fluid">
          <div className="row">
            <main className="col-md-12  px-md-4 leftsidecontent">
              <div className="row mx-0 g-3">
                <div className="text-center">
                  {dash?.timerstatus === 1 && (
                    <Timer endTime={dash?.endtimer} />
                  )}
                </div>
                <div className="col-xl-3 col-md-6 col-12">
                  <div className="card border-0 topcard profilcard h-100">
                    <div className="card-body">
                      <div className="card_profileimg mb-3">
                        <img
                          className="w-100 h-100 rounded-pill"
                          src="./assets/image/profile.png"
                          alt="profile"
                        />
                      </div>
                      <div className="userid">User Id:</div>
                      <div className="number my-2">{dash.userid}</div>
                      <div className="wallet ">Affiliate Link</div>
                      <p
                        className="text-center copytext border-theme1"
                        onClick={CopyReferralLink}
                        id="walletAddress"
                      >
                        Copy Referral Link
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-md-6 col-12 ">
                  <div className="card topcard border-0 h-100 p-3">
                    <div className="row mx-0 h-100">
                      <div className="col-6 px-1 flex-column justify-content-center d-flex h-100 widthmobile">
                        <div className="totoinvestment">
                          <img
                            className="w-100 h-100"
                            src="./assets/image/Total_Investment.svg"
                            alt="Total_Investment"
                          />
                        </div>
                        <div className="heading">Total Investment</div>
                        <div className="total fw-bold text-white text-center">
                          {dash.TotalPackage}
                        </div>
                      </div>
                      <div className="col-6 px-1 flex-column justify-content-center d-flex h-100 widthmobile">
                        <div className="totoinvestment">
                          <img
                            className="w-100 h-100"
                            src="./assets/image/Kick_Investment.svg"
                            alt="Kick_Investment"
                          />
                        </div>
                        <div className="heading">Current Investment</div>
                        <div className="total fw-bold text-white text-center">
                          {dash.CurrentPackage}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-12 px-0">
                  <div className="row mx-0 gy-3">
                    <div className="col-12 col-sm-6 col-md-6 col-xl-12">
                      <div className="card bg-transparent topcard">
                        <div className="card-body">
                          <div className="card-heading mb-2 text-white fw-bold">
                            Direct
                          </div>
                          <div className="d-flex align-items-center justify-content-between mb-2">
                            <div className="d-flex align-items-center gap-2 text-white">
                              Active
                            </div>
                            <div className="active text-white">
                              {dash.TotalActiveReferral}
                            </div>
                          </div>
                          <div className="d-flex align-items-center justify-content-between mb-2">
                            <div className="d-flex align-items-center gap-2 text-white">
                              In-Active
                            </div>
                            <div className="in_active text-white">
                              {dash.TotalInactiveActiveReferral}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-xl-12">
                      <div className="card bg-transparent topcard">
                        <div className="card-body">
                          <div className="card-heading text-white fw-bold mb-2">
                            Team
                          </div>
                          <div className="d-flex align-items-center justify-content-between mb-2">
                            <div className="d-flex align-items-center gap-2 text-white">
                              Active
                            </div>
                            <div className="active text-white">
                              {dash.TotalActiveTeam}
                            </div>
                          </div>
                          <div className="d-flex align-items-center justify-content-between mb-2">
                            <div className="d-flex align-items-center gap-2 text-white">
                              In-Active
                            </div>
                            <div className="in_active text-white">
                              {dash.TotalInactiveTeam}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row g-3 mx-0 my-3 samllcardsection">
                <div className="col-xl-3 col-12 col-sm-6 col-md-4">
                  <Link to="/MatrixDownline">
                    <div className="card">
                      <div className="card-body">
                        <div className="row mx-0">
                          <div className="col-3 px-0">
                            <div className="cardimage">
                              <img
                                className="w-100 h-100"
                                src="./assets/image/1.png"
                                alt="TodayBusiness"
                              />
                            </div>
                          </div>
                          <div className="col-9 px-0">
                            <div className="card_heading">Matrix Downline</div>
                            <div className="sub_heading">
                              {dash.TotalMatrixDownline}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                {/* <div className="col-xl-3 col-12 col-sm-6 col-md-4">
                  <Link to="/AutopoolIncome">
                    <div className="card">
                      <div className="card-body">
                        <div className="row mx-0">
                          <div className="col-3 px-0">
                            <div className="cardimage">
                              <img
                                className="w-100 h-100"
                                src="./assets/image/TodayBusiness.svg"
                                alt="TodayBusiness"
                              />
                            </div>
                          </div>
                          <div className="col-9 px-0">
                            <div className="card_heading">ROI Income</div>
                            <div className="sub_heading">{dash.Roi_Income}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div> */}
                <div className="col-xl-3 col-12 col-sm-6 col-md-4">
                  <Link to="/stakingGlobalteam">
                    <div className="card">
                      <div className="card-body">
                        <div className="row mx-0">
                          <div className="col-3 px-0">
                            <div className="cardimage">
                              <img
                                className="w-100 h-100"
                                src="./assets/image/2.png"
                                alt="TodayBusiness"
                              />
                            </div>
                          </div>
                          <div className="col-9 px-0">
                            <div className="card_heading">Rapid Income</div>
                            <div className="sub_heading">
                              {dash.fasttrack_income}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-xl-3 col-12 col-sm-6 col-md-4">
                  <Link to="/DirectIncomeStaking">
                    <div className="card">
                      <div className="card-body">
                        <div className="row mx-0">
                          <div className="col-3 px-0">
                            <div className="cardimage">
                              <img
                                className="w-100 h-100"
                                src="./assets/image/3.jpg"
                                alt="TodayBusiness"
                              />
                            </div>
                          </div>
                          <div className="col-9 px-0">
                            <div className="card_heading">Direct Income</div>
                            <div className="sub_heading">
                              {dash.Direct_Income}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-xl-3 col-12 col-sm-6 col-md-4">
                  <Link to="/StakingLevelBonus">
                    <div className="card">
                      <div className="card-body">
                        <div className="row mx-0">
                          <div className="col-3 px-0">
                            <div className="cardimage">
                              <img
                                className="w-100 h-100"
                                src="./assets/image/4.png"
                                alt="TodayBusiness"
                              />
                            </div>
                          </div>
                          <div className="col-9 px-0">
                            <div className="card_heading">Level Income</div>
                            <div className="sub_heading">
                              {dash.Level_Income}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="col-xl-3 col-12 col-sm-6 col-md-4">
                  <Link to="/ReturnIncome">
                    <div className="card">
                      <div className="card-body">
                        <div className="row mx-0">
                          <div className="col-3 px-0">
                            <div className="cardimage">
                              <img
                                className="w-100 h-100"
                                src="./assets/image/5.png"
                                alt="TodayBusiness"
                              />
                            </div>
                          </div>
                          <div className="col-9 px-0">
                            <div className="card_heading">
                              Return Back Income
                            </div>
                            <div className="sub_heading">
                              {dash.Return_Income}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-xl-3 col-12 col-sm-6 col-md-4">
                  {/* <Link to="/withdrawal"> */}
                  <div className="card">
                    <div className="card-body">
                      <div className="row mx-0">
                        <div className="col-3 px-0">
                          <div className="cardimage">
                            <img
                              className="w-100 h-100"
                              src="./assets/image/8.png"
                              alt="NetIncome"
                            />
                          </div>
                        </div>
                        <div className="col-9 px-0">
                          <div className="card_heading">
                           CTO Income
                          </div>
                          <div className="sub_heading">
                            {dash?.universal_bonus}
                          </div>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                  {/* </Link> */}
                </div>
                <div className="col-xl-3 col-12 col-sm-6 col-md-4">
                  <Link to="/MatrixIncome">
                    <div className="card">
                      <div className="card-body">
                        <div className="row mx-0">
                          <div className="col-3 px-0">
                            <div className="cardimage">
                              <img
                                className="w-100 h-100"
                                src="./assets/image/TodayBusiness.svg"
                                alt="TodayBusiness"
                              />
                            </div>
                          </div>
                          <div className="col-9 px-0">
                            <div className="card_heading">Matrix Income</div>
                            <div className="sub_heading">
                              {dash.Club_Bonus_Income}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                {/* <div className="col-xl-3 col-12 col-sm-6 col-md-4">
                  <Link to="/RewardIncome">
                    <div className="card">
                      <div className="card-body">
                        <div className="row mx-0">
                          <div className="col-3 px-0">
                            <div className="cardimage">
                              <img
                                className="w-100 h-100"
                                src="./assets/image/TodayBusiness.svg"
                                alt="TodayBusiness"
                              />
                            </div>
                          </div>
                          <div className="col-9 px-0">
                            <div className="card_heading">Reward Income</div>
                            <div className="sub_heading">
                              {dash.Reward_Income}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div> */}

                {/* <div className="col-xl-3 col-12 col-sm-6 col-md-4">
                  <Link to="/WithdrawalLevelIncome">
                    <div className="card">
                      <div className="card-body">
                        <div className="row mx-0">
                          <div className="col-3 px-0">
                            <div className="cardimage">
                              <img
                                className="w-100 h-100"
                                src="./assets/image/TodayBusiness.svg"
                                alt="TodayBusiness"
                              />
                            </div>
                          </div>
                          <div className="col-9 px-0">
                            <div className="card_heading">
                              Withdrawal Level Income
                            </div>
                            <div className="sub_heading">
                              {dash.Withdrawal_Level_Income}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-xl-3 col-12 col-sm-6 col-md-4">
                  <Link to="/SingleLegIncome">
                    <div className="card">
                      <div className="card-body">
                        <div className="row mx-0">
                          <div className="col-3 px-0">
                            <div className="cardimage">
                              <img
                                className="w-100 h-100"
                                src="./assets/image/TodayBusiness.svg"
                                alt="TodayBusiness"
                              />
                            </div>
                          </div>
                          <div className="col-9 px-0">
                            <div className="card_heading">
                              Single Leg Income
                            </div>
                            <div className="sub_heading">
                              {dash.SingleLeg_Income}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div> */}
                <div className="col-xl-3 col-12 col-sm-6 col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="row mx-0">
                        <div className="col-3 px-0">
                          <div className="cardimage">
                            <img
                              className="w-100 h-100"
                              src="./assets/image/TotalIncome.svg"
                              alt="TotalIncome"
                            />
                          </div>
                        </div>
                        <div className="col-9 px-0">
                          <div className="card_heading">Total Team Business </div>
                          <div className="sub_heading">{dash.totalTeamBusiness}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-12 col-sm-6 col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="row mx-0">
                        <div className="col-3 px-0">
                          <div className="cardimage">
                            <img
                              className="w-100 h-100"
                              src="./assets/image/TotalIncome.svg"
                              alt="TotalIncome"
                            />
                          </div>
                        </div>
                        <div className="col-9 px-0">
                          <div className="card_heading">Total Income</div>
                          <div className="sub_heading">{dash.TotalIncome}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-12 col-sm-6 col-md-4">
                  <Link to="/withdrawalhistory">
                    <div className="card">
                      <div className="card-body">
                        <div className="row mx-0">
                          <div className="col-3 px-0">
                            <div className="cardimage">
                              <img
                                className="w-100 h-100"
                                src="./assets/image/totalw.png"
                                alt="totalw"
                              />
                            </div>
                          </div>
                          <div className="col-9 px-0">
                            <div className="card_heading">Total Withdrawal</div>
                            <div className="sub_heading">
                              {dash.WithDrawal_Amount}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-xl-3 col-12 col-sm-6 col-md-4">
                  <Link to="/withdrawal">
                    <div className="card">
                      <div className="card-body">
                        <div className="row mx-0">
                          <div className="col-3 px-0">
                            <div className="cardimage">
                              <img
                                className="w-100 h-100"
                                src="./assets/image/6.png"
                                alt="NetIncome"
                              />
                            </div>
                          </div>
                          <div className="col-9 px-0">
                            <div className="card_heading">Net Income</div>
                            <div className="sub_heading">
                              {dash.Net_Balance}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
               
                <div className="col-xl-3 col-12 col-sm-6 col-md-4">
                  {/* <Link to="/withdrawal"> */}
                  <div className="card">
                    <div className="card-body">
                      <div className="row mx-0">
                        <div className="col-3 px-0">
                          <div className="cardimage">
                            <img
                              className="w-100 h-100"
                              src="./assets/image/8.png"
                              alt="NetIncome"
                            />
                          </div>
                        </div>
                        <div className="col-9 px-0">
                          <div className="card_heading">
                            Used Matrix Wallet
                          </div>
                          <div className="sub_heading">
                            {dash?.upgrade_amount}
                          </div>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                  {/* </Link> */}
                </div>
                
               
              </div>
              {/* <div className="row mx-0 my-4 progressbarSection">
              <div className="imageprogress d-flex mb-2"><img clasa="w-100 h-100" src="/assets/image/logo.png" alt=""></div>
              <div className="progress px-0 mb-2 ">
                  <div className="progress-bar progress-bar-striped" role="progressbar" aria-label="Default striped example" style="width: 10%" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
              <p className="text-white fs-6 text-center text-sm-start"></p>
          </div> */}
              <div className="row mx-0 sectioncardprogresbar g-3">
                <div className="col-lg-8">
                  <div className="card leftcard p-0 h-100">
                    <div className="card-heading px-3 py-3 text-start">
                      All Income
                    </div>
                    <div className="card-body p-0">
                      <div className="table table-responsive pt-4">
                        <table className="table mb-0">
                          {/* <thead>
                            <tr>
                              <th scope="col">
                                <div className="mt-2">#</div>
                              </th>
                              <th scope="col">
                                <div className="mt-2">Name</div>
                              </th>
                              <th scope="col">
                                <div className="mt-2">Popularity</div>
                              </th>
                              <th scope="col">
                                <div className="mt-2">Sales</div>
                              </th>
                            </tr>
                          </thead> */}
                          <tbody>
                            {/* <tr>
                              <td>
                                <div className="mt-2">01</div>
                              </td>
                              <td>
                                <div className="mt-2">ROI Income</div>
                              </td>
                              <td>
                                <div className="progress progress3">
                                  <div
                                    className="progress-bar"
                                    role="progressbar"
                                    aria-label="Basic example"
                                    style={{ width: "29%" }}
                                    aria-valuenow={29}
                                    aria-valuemin={0}
                                    aria-valuemax={100}
                                  />
                                </div>
                              </td>
                              <td>
                                <button className="btn btn4 px-3 py-2">
                                  {dash.Roi_Income}
                                </button>
                              </td>
                            </tr> */}
                            <tr>
                              <td>
                                <div className="mt-2">01</div>
                              </td>
                              <td>
                                <div className="mt-2">Rapid Income</div>
                              </td>
                              <td>
                                <div className="progress progress7">
                                  <div
                                    className="progress-bar"
                                    role="progressbar"
                                    aria-label="Basic example"
                                    style={{ width: "29%" }}
                                    aria-valuenow={29}
                                    aria-valuemin={0}
                                    aria-valuemax={100}
                                  />
                                </div>
                              </td>
                              <td>
                                <button className="btn btn4 px-3 py-2" style={{width:'100px'}}>
                                  {dash.fasttrack_income}
                                </button>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="mt-2">02</div>
                              </td>
                              <td>
                                <div className="mt-2">Direct Income</div>
                              </td>
                              <td>
                                <div className="progress progress2">
                                  <div
                                    className="progress-bar"
                                    role="progressbar"
                                    aria-label="Basic example"
                                    style={{ width: "17%" }}
                                    aria-valuenow={17}
                                    aria-valuemin={0}
                                    aria-valuemax={100}
                                  />
                                </div>
                              </td>
                              <td>
                                <button className="btn btn2 px-3 py-2" style={{width:'100px'}}>
                                  {dash.Direct_Income}
                                </button>
                              </td>
                            </tr>
                            {/* <tr>
                              <td>
                                <div className="mt-2">03</div>
                              </td>
                              <td>
                                <div className="mt-2">
                                 Today Income
                                </div>
                              </td>
                              <td>
                                <div className="progress progress3">
                                  <div
                                    className="progress-bar"
                                    role="progressbar"
                                    aria-label="Basic example"
                                    style={{ width: "19%" }}
                                    aria-valuenow={19}
                                    aria-valuemin={0}
                                    aria-valuemax={100}
                                  />
                                </div>
                              </td>
                              <td>
                                <button className="btn btn3 px-3 py-2">
                                  19%
                                </button>
                              </td>
                            </tr> */}
                            <tr>
                              <td>
                                <div className="mt-2">03</div>
                              </td>
                              <td>
                                <div className="mt-2">Level Income</div>
                              </td>
                              <td>
                                <div className="progress progress4">
                                  <div
                                    className="progress-bar"
                                    role="progressbar"
                                    aria-label="Basic example"
                                    style={{ width: "25%" }}
                                    aria-valuenow={29}
                                    aria-valuemin={0}
                                    aria-valuemax={100}
                                  />
                                </div>
                              </td>
                              <td>
                                <button className="btn btn4 px-3 py-2" style={{width:'100px'}}>
                                  {dash.Level_Income}
                                </button>
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <div className="mt-2">04</div>
                              </td>
                              <td>
                                <div className="mt-2">Return Back Income</div>
                              </td>
                              <td>
                                <div className="progress progress6">
                                  <div
                                    className="progress-bar"
                                    role="progressbar"
                                    aria-label="Basic example"
                                    style={{ width: "29%" }}
                                    aria-valuenow={29}
                                    aria-valuemin={0}
                                    aria-valuemax={100}
                                  />
                                </div>
                              </td>
                              <td>
                                <button className="btn btn4 px-3 py-2" style={{width:'100px'}}>
                                  {dash.Return_Income}
                                </button>
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <div className="mt-2">05</div>
                              </td>
                              <td>
                                <div className="mt-2">Matrix Income</div>
                              </td>
                              <td>
                                <div className="progress progress1">
                                  <div
                                    className="progress-bar"
                                    role="progressbar"
                                    aria-label="Basic example"
                                    style={{ width: "29%" }}
                                    aria-valuenow={29}
                                    aria-valuemin={0}
                                    aria-valuemax={100}
                                  />
                                </div>
                              </td>
                              <td>
                                <button className="btn btn4 px-3 py-2" style={{width:'100px'}}>
                                  {dash.Club_Bonus_Income}
                                </button>
                              </td>
                            </tr>

                            {/* <tr>
                              <td>
                                <div className="mt-2">06</div>
                              </td>
                              <td>
                                <div className="mt-2">Reward Income</div>
                              </td>
                              <td>
                                <div className="progress progress2">
                                  <div
                                    className="progress-bar"
                                    role="progressbar"
                                    aria-label="Basic example"
                                    style={{ width: "29%" }}
                                    aria-valuenow={29}
                                    aria-valuemin={0}
                                    aria-valuemax={100}
                                  />
                                </div>
                              </td>
                              <td>
                                <button className="btn btn4 px-3 py-2">
                                  {dash.Reward_Income}
                                </button>
                              </td>
                            </tr> */}

                            {/* <tr>
                              <td>
                                <div className="mt-2">08</div>
                              </td>
                              <td>
                                <div className="mt-2">
                                  Withdrawal Level Income
                                </div>
                              </td>
                              <td>
                                <div className="progress progress4">
                                  <div
                                    className="progress-bar"
                                    role="progressbar"
                                    aria-label="Basic example"
                                    style={{ width: "29%" }}
                                    aria-valuenow={29}
                                    aria-valuemin={0}
                                    aria-valuemax={100}
                                  />
                                </div>
                              </td>
                              <td>
                                <button className="btn btn4 px-3 py-2">
                                  {dash.Withdrawal_Level_Income}
                                </button>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="mt-2">09</div>
                              </td>
                              <td>
                                <div className="mt-2">Single Leg Income</div>
                              </td>
                              <td>
                                <div className="progress progress7">
                                  <div
                                    className="progress-bar"
                                    role="progressbar"
                                    aria-label="Basic example"
                                    style={{ width: "29%" }}
                                    aria-valuenow={29}
                                    aria-valuemin={0}
                                    aria-valuemax={100}
                                  />
                                </div>
                              </td>
                              <td>
                                <button className="btn btn4 px-3 py-2">
                                  {dash.SingleLeg_Income}
                                </button>
                              </td>
                            </tr> */}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="card h-100 graph1">
                    <div className="card-body">
                      <div className="card-heading text-start">
                        Total Income
                      </div>
                      {/* <div className="card-title text-start">Total Expense</div> */}
                      <br></br>
                      <div className="earningpoint text-start">
                        ${dash.TotalIncome}
                      </div>
                      {/* <p className="text-start">
                        Profit is 48% More than last Month
                      </p> */}
                      <br></br>
                      <br></br>
                      <div className="chartmain mt-5">
                      <div
                          className={`semi-donut ${
                            dash?.EarnPer >= 90?"donutcolred" : "donutcol"
                          } margin`}
                        >
                          {dash?.EarnPer}%
                        </div>
                        {/* <div
                          className={`semi-donut margin ${
                            parseFloat(dash.EarnPer).toFixed(2) > 90 ? "red-donat" : ""
                          }`}
                        >
                          {dash.EarnPer} %
                        </div> */}
                      </div>
                      <br></br>
                      <p className="text-white othertext">
                        You have earned Total {dash.EarnPer} % (
                        {dash.EarnAmount} $) out of {dash.CapingPer} % (
                        {dash.MaxIncome} $) of total investment (
                        {dash.TotalPackage} $).
                      </p>
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-4 col-md-6">
                  <a href="Support.html">
                    <div className="card h-100 bg-transparent last-card">
                      <div className="card-body">
                        <div className="row mx-0 align-items-center">
                          <div className="col-3 px-0">
                            <div className="cardimage">
                              <img
                                className="w-100 h-100"
                                src="./assets/image/Support.svg"
                                alt="investment"
                              />
                            </div>
                          </div>
                          <div className="col-9 px-0">
                            <div className="card_heading">Support</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-lg-4 col-md-6">
                  <a
                    href="https://t.me/telegram"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="card h-100 bg-transparent last-card">
                      <div className="card-body">
                        <div className="row mx-0 align-items-center">
                          <div className="col-3 px-0">
                            <div className="cardimage">
                              <img
                                className="w-100 h-100"
                                src="./assets/image/telegram.svg"
                                alt="investment"
                              />
                            </div>
                          </div>
                          <div className="col-9 px-0">
                            <div className="card_heading">Telegram Link</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-lg-4 col-md-6">
                  <Link to="/newschannel">
                    <div className="card h-100 bg-transparent last-card">
                      <div className="card-body">
                        <div className="row mx-0 align-items-center">
                          <div className="col-3 px-0">
                            <div className="cardimage">
                              <img
                                className="w-100 h-100"
                                src="./assets/image/news.svg"
                                alt="news"
                              />
                            </div>
                          </div>
                          <div className="col-9 px-0">
                            <div className="card_heading">News Channel</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div> */}
              </div>
            </main>
            {/*---------------------------------------Dashboard Include----------------------------------------*/}
            <Footer />
          </div>
        </div>
      </>
    </>
  );
}
