import { BrowserRouter, Route, Routes } from "react-router-dom";
import UserDashboard from "./Components/UserDashboard/UserDashboard";
import Login from "./Components/Login/Login";
import Registration from "./Components/Registration/Registration";
import Activation from "./Components/ActivateUpgrade/Activation";
import ActivationHistory from "./Components/ActivationHistory/ActivationHistory";
import StakingBonus from "./Components/Staking/StakingBonus";
import StakingLevelBonus from "./Components/Staking/StakingLevelBonus";
import StakingTeamFounder from "./Components/Staking/StakingTeamFounder";
import StakingGlobalTeam from "./Components/Staking/StakingGlobalTeam";
import ReturnIncome from "./Components/Staking/ReturnIncome";
import MatrixIncome from "./Components/Staking/MatrixIncome";
import RewardIncome from "./Components/Staking/RewardIncome";
import AutopoolIncome from "./Components/Staking/AutopoolIncome";
import MyReferrals from "./Components/TeamDetails/MyReferrals";
import MatrixDownline from "./Components/TeamDetails/MatrixDownline";
import MyTeam from "./Components/TeamDetails/MyTeam";
import LevelDetails from "./Components/TeamDetails/LevelDetails";
import DailyBusiness from "./Components/TeamDetails/DailyBusiness";
import Withdrawal from "./Components/Withdrawal/Withdrawal";
import WithdrawalHistory from "./Components/Withdrawal/WithdrawalHistory";
import SelfAddress from "./Components/Address/SelfAddress";
import Support from "./Components/Support/Support";
import NewsChannel from "./Components/News/NewsChannel";
import StakingDailyBusiness from "./Components/Staking/StakingDailyBusiness";
import StakingBusinessReport from "./Components/Staking/StakingBusinessReport";
import AgcStakingBonus from "./Components/AgcStaking/AgcStakingBonus";
import StakingTeamBusiness from "./Components/Staking/StakingTeamBusiness";
import DirectIncomeStaking from "./Components/Staking/DirectIncomeStaking";
import WithdrawalLevelIncome from "./Components/Staking/WithdrawalLevelIncome";
import SingleLegIncome from "./Components/Staking/SingleLegIncome";
import TodaysINcomeStaking from "./Components/Income/TodaysINcomeStaking";
import TreeView from "./Components/TreeView/TreeView";
import Privateroutes from "./Utility/Privateroutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StakingTotalBusiness from "./Components/Staking/StakingTotalBussiness";
import GameWalletHistory from "./Components/GameWallet/ConvertGameWalletHistory";
import ConvertGameWallet from "./Components/GameWallet/ConvertGameWallet";
import Home from "./Components/Home/Home";
import MatrixActivation from "./Components/Matrix ActivationUpgrade/MatrixActivation";
import MatrixActivationHistory from "./Components/Matrix ActivationUpgrade/MatrixActivationHistory";
import { useEffect } from "react";
import CtoIncome from "./Components/Staking/CtoIncome";


function App() {
  //   useEffect(() => {
  //   document.addEventListener("contextmenu", function (e) {
  //     e.preventDefault();
  //     debugger;
  //   });

  //   document.onkeydown = function (e) {
  //     if (
  //       (e.ctrlKey && (e.keyCode === 73 || e.keyCode === 85)) ||
  //       e.keyCode === 123
  //     ) {
  //       return false;
  //     }
  //   };
  // }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <ToastContainer />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Registration />} />
          <Route element={<Privateroutes />}>
            <Route path="/dashboard" element={<UserDashboard />} />
            <Route path="/ActivationUpgrade" element={<Activation />} />
            <Route path="/ActivationHistory" element={<ActivationHistory />} />\
            {/* <Route path="/MatrixActivationUpgrade" element={<MatrixActivation />} />
            <Route path="/MatrixActivationHistory" element={<MatrixActivationHistory />} /> */}
            <Route path="/stakingBonus" element={<StakingBonus />} />
            <Route path="/stakingLevelBonus" element={<StakingLevelBonus />} />
            <Route
              path="/stakingTeamFounder"
              element={<StakingTeamFounder />}
            />
            <Route path="/stakingGlobalteam" element={<StakingGlobalTeam />} />
            <Route path="/ctoincome" element={<CtoIncome />} />
            <Route
              path="/stakingdailybusiness"
              element={<StakingDailyBusiness />}
            />
            <Route
              path="/StakingTotalBusiness"
              element={<StakingTotalBusiness />}
            />
            <Route
              path="/stakingbusinessreport"
              element={<StakingBusinessReport />}
            />
            <Route
              path="/stakingTeambusiness"
              element={<StakingTeamBusiness />}
            />
            <Route
              path="/Directincomestaking"
              element={<DirectIncomeStaking />}
            />
            <Route path="/ReturnIncome" element={<ReturnIncome />} />
            <Route path="/MatrixIncome" element={<MatrixIncome />} />
            <Route path="/RewardIncome" element={<RewardIncome />} />
            <Route path="/AutopoolIncome" element={<AutopoolIncome />} />
            <Route path="/SingleLegIncome" element={<SingleLegIncome />} />
            <Route
              path="/WithdrawalLevelIncome"
              element={<WithdrawalLevelIncome />}
            />
            <Route
              path="/todayincomeStaking"
              element={<TodaysINcomeStaking />}
            />
            <Route path="/agcStakingbonus" element={<AgcStakingBonus />} />
            <Route path="/myreferral" element={<MyReferrals />} />
            <Route path="/MatrixDownline" element={<MatrixDownline />} />
            <Route path="/myteam" element={<MyTeam />} />
            <Route path="/leveldetails" element={<LevelDetails />} />
            <Route path="/dailyBusiness" element={<DailyBusiness />} />
            <Route path="/withdrawal" element={<Withdrawal />} />
            <Route path="/withdrawalhistory" element={<WithdrawalHistory />} />
            <Route path="/selfaddress" element={<SelfAddress />} />
            {/* <Route path="/support" element={<Support />} />
            <Route path="/newschannel" element={<NewsChannel />} /> */}
            <Route
              path="/ConvertGameWallet_USDT_Staking"
              element={<ConvertGameWallet />}
            />
            <Route path="/GameWalletHistory" element={<GameWalletHistory />} />
            <Route path="/TreeView" element={<TreeView />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
