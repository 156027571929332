import React, { useEffect, useState } from "react";
import Footer from "../Footer/Footer";
import MobileNavbar from "../Navbar/MobileNavbar";
import Navbar from "../Navbar/Navbar";
import Header from "../Header/Header";
// import "./Tree.css";
// import Tree from "../TreeView/treeimg/maintree.png";
// import redboy from "../TreeView/treeimg/redboy.png";
// import greenboy from "../TreeView/treeimg/greenboy.png";
// import blackboy from "../TreeView/treeimg/blackboy.png";
import user3red from "./treeimg/default.png";
import tree_image_medium from "./treeimg/dow.png";
import Active from "./treeimg/green.png";
import tree_image_small from "./treeimg/download (2).png";
import "./Treeview.css";
import { updateAuth } from "../../Redux/AuthSlice";
import { useDispatch, useSelector } from "react-redux";
import { API } from "../../Utility/API";

function TreeView() {
  let bol = true;

  const [Idnumer, setIdnumer] = useState();
  const [arrValue, setArrValue] = useState([]);
  const [getValue, setgetValue] = useState(1);
  const [userdata, setuserdata] = useState([
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "0",
      right_business: "",
      Sponsor: "",
      Downline: "",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
      Downline: "",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
      Downline: "",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
      Downline: "",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
      Downline: "",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
      Downline: "",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
      Downline: "",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
      Downline: "",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
      Downline: "",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
      Downline: "",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
      Downline: "",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
      Downline: "",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
      Downline: "",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
      Downline: "",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
      Downline: "",
    },
  ]);

  const dispatch = useDispatch();

  const user = useSelector((state) => state.Auth.userId);
  const token = useSelector((state) => state.Auth.jwtToken);

  const Tree_API = async () => {
    try {
      let responce = await API.post(
        "MatrixTree",
        {
          uid: user,
          usersession: user,
          package: getValue,
        },
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      responce = responce.data.data[0];
      console.log("res-->", responce);
      setuserdata([]);

      let arr = [];
      responce.forEach((item) => {
        arr.push({
          name: item.fname,
          id: item.uid,
          Activation_date: item.activation_date,
          // package: item.packageamount,
          package_amount: item.packageamount,
          level: item.LEVEL,
          Sponsor: item.sid,
          Downline: item.downline,
        });
      });

      setuserdata(arr);
    } catch (e) {
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            isAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
      }
    }
  };

  useEffect(() => {
    Tree_API();
  }, [getValue]);

  const getIdArray = (arr) => {
    setArrValue([...arrValue, arr]);
  };

  return (
    <>
      <Header />
      <Navbar />
      <MobileNavbar />

      <div className="container-fluid Activate_UpgradeSectiob tablecardSection">
        <div className="row">
          <main className="col-md-12 mx-auto col-lg-12 col-12 px-md-4 mt-0">
            <div className="row mx-0 p-3">
              <div className="col-lg-12 mx-auto">
                <div className="card">
                  <div className="card-body ">
                    <div className="cardheading text-start">Matrix Tree</div>
                    <div className="page-content pb-5 treeviewpage">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="search-box">
                            <form>
                              <div className=" d-flex  mod_form_wrapper">
                                <div className="col-md-12">
                                  <button
                                    type="button"
                                    className={`btn-tree btn btn-sm topmargin ${
                                      getValue !== 1
                                        ? "yellow-btn"
                                        : "green-btn"
                                    }`}
                                    onClick={() => setgetValue(1)}
                                  >
                                    $ 10
                                  </button>
                                  &nbsp;
                                  <button
                                    type="button"
                                    className={`btn-tree btn btn-sm topmargin ${
                                      getValue !== 2
                                        ? "yellow-btn"
                                        : "green-btn"
                                    }`}
                                    onClick={() => setgetValue(2)}
                                  >
                                    $ 20
                                  </button>
                                  &nbsp;
                                  <button
                                    type="button"
                                    className={`btn-tree btn btn-sm topmargin ${
                                      getValue !== 3
                                        ? "yellow-btn"
                                        : "green-btn"
                                    }`}
                                    onClick={() => setgetValue(3)}
                                  >
                                    $ 40
                                  </button>
                                  &nbsp;
                                  <button
                                    type="button"
                                    className={`btn-tree btn btn-sm topmargin ${
                                      getValue !== 4
                                        ? "yellow-btn"
                                        : "green-btn"
                                    }`}
                                    onClick={() => setgetValue(4)}
                                  >
                                    $ 80
                                  </button>
                                  &nbsp;
                                  <button
                                    type="button"
                                    className={`btn-tree btn btn-sm topmargin ${
                                      getValue !== 5
                                        ? "yellow-btn"
                                        : "green-btn"
                                    }`}
                                    onClick={() => setgetValue(5)}
                                  >
                                    $ 160
                                  </button>
                                  &nbsp;
                                  <button
                                    type="button"
                                    className={`btn-tree btn btn-sm topmargin ${
                                      getValue !== 6
                                        ? "yellow-btn"
                                        : "green-btn"
                                    }`}
                                    onClick={() =>
                                      // setIdnumer(uId), setgetValue(6), referral_API()
                                      setgetValue(6)
                                    }
                                  >
                                    $ 320
                                  </button>
                                  &nbsp;
                                  <button
                                    type="button"
                                    className={`btn-tree btn btn-sm topmargin ${
                                      getValue !== 7
                                        ? "yellow-btn"
                                        : "green-btn"
                                    }`}
                                    onClick={() =>
                                      // setIdnumer(uId), setgetValue(7), referral_API()
                                      setgetValue(7)
                                    }
                                  >
                                    $ 640
                                  </button>
                                  &nbsp;
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="card">
                            <div
                              id="tree"
                              className="treeview"
                              style={{ margin: "40px auto" }}
                            >
                              <div className="treemember hors_ip">
                                <div className="dropdown">
                                  <button className="dropbtn">
                                    <img
                                      src={
                                        userdata[0].package_amount > 0
                                          ? Active
                                          : userdata[0].id === ""
                                          ? user3red
                                          : user3red
                                      }
                                      alt=""
                                      onClick={() => (
                                        // setIdnumer(userdata[0].id),  referral_API()
                                        setIdnumer(userdata[0].id),
                                        getIdArray(userdata[0].id)
                                      )}
                                      className="abc"
                                    />
                                  </button>

                                  <div
                                    className="span"
                                    style={{ color: "#fff" }}
                                  >
                                    {/* {userdata[0].name}  <br /> */}

                                    {userdata[0].id}
                                  </div>
                                  <div className="dropdown-content ">
                                    <table
                                      className="tables dropdown_width"
                                      cellPadding="0px"
                                      cellSpacing="0px"
                                    >
                                      <tbody>
                                        <tr className="fgtybmd">
                                          <td className="table_heading">
                                            Sponsor :
                                          </td>
                                          <td className="grtydfbc" colSpan="3">
                                            {userdata[0].Sponsor}
                                          </td>
                                        </tr>
                                        <tr className="fgtybmd">
                                          <td className="table_heading">
                                            Activation Date :
                                          </td>
                                          <td className="grtydfbc" colSpan="3">
                                            {userdata[0].Activation_date}
                                          </td>
                                        </tr>
                                        <tr className="fgtybmd">
                                          <td className="table_heading">
                                            Downline :
                                          </td>
                                          <td className="grtydfbc" colSpan="3">
                                            {userdata[0].Downline}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>

                              <div className="connecter1">
                                <img
                                  src={tree_image_small}
                                  alt=""
                                  style={{ width: "640px", height: "33px" }}
                                />
                              </div>

                              <div className="tree_row1">
                                <div className="row_22_child1">
                                  <div className="dropdown">
                                    <button className="dropbtn">
                                      <img
                                        src={
                                          userdata[1].package_amount > 0
                                            ? Active
                                            : userdata[1].id === ""
                                            ? user3red
                                            : user3red
                                        }
                                        alt=""
                                        onClick={() => (
                                          setIdnumer(userdata[1].id),
                                          getIdArray(userdata[1].id)
                                        )}
                                        className="abc"
                                      />
                                    </button>
                                    <div
                                      className="span"
                                      style={{ color: "#fff" }}
                                    >
                                      {/* {userdata[1].name}  <br /> */}

                                      {userdata[1].id}
                                    </div>
                                    <div className="dropdown-content ">
                                      <table
                                        className="tables dropdown_width"
                                        cellPadding="0px"
                                        cellSpacing="0px"
                                      >
                                        <tbody>
                                          <tr className="fgtybmd">
                                            <td className="table_heading">
                                              Sponsor :
                                            </td>
                                            <td
                                              className="grtydfbc"
                                              colSpan="3"
                                            >
                                              {userdata[1].Sponsor}
                                            </td>
                                          </tr>
                                          <tr className="fgtybmd">
                                            <td className="table_heading">
                                              Activation Date :
                                            </td>
                                            <td
                                              className="grtydfbc"
                                              colSpan="3"
                                            >
                                              {userdata[1].Activation_date}
                                            </td>{" "}
                                          </tr>
                                          <tr className="fgtybmd">
                                            <td className="table_heading">
                                              Downline :
                                            </td>
                                            <td
                                              className="grtydfbc"
                                              colSpan="3"
                                            >
                                              {userdata[1].Downline}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                                <div className="row_22_child1">
                                  <div className="dropdown">
                                    <button className="dropbtn">
                                      <img
                                        src={
                                          userdata[2].package_amount > 0
                                            ? Active
                                            : userdata[2].id === ""
                                            ? user3red
                                            : user3red
                                        }
                                        alt=""
                                        onClick={() => (
                                          setIdnumer(userdata[2].id),
                                          getIdArray(userdata[2].id)
                                        )}
                                        className="abc"
                                      />
                                    </button>
                                    <div
                                      className="span"
                                      style={{ color: "#fff" }}
                                    >
                                      {userdata[2].id}
                                    </div>
                                    <div className="dropdown-content ">
                                      <table
                                        className="tables dropdown_width"
                                        cellPadding="0px"
                                        cellSpacing="0px"
                                      >
                                        <tbody>
                                          <tr className="fgtybmd">
                                            <td className="table_heading">
                                              Sponsor :
                                            </td>
                                            <td
                                              className="grtydfbc"
                                              colSpan="3"
                                            >
                                              {userdata[2].Sponsor}
                                            </td>
                                          </tr>
                                          <tr className="fgtybmd">
                                            <td className="table_heading">
                                              Activation Date :
                                            </td>
                                            <td
                                              className="grtydfbc"
                                              colSpan="3"
                                            >
                                              {userdata[2].Activation_date}
                                            </td>{" "}
                                          </tr>
                                          <tr className="fgtybmd">
                                            <td className="table_heading">
                                              Downline :
                                            </td>
                                            <td
                                              className="grtydfbc"
                                              colSpan="3"
                                            >
                                              {userdata[2].Downline}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>

                                <div className="row_22_child1">
                                  <div className="dropdown">
                                    <button className="dropbtn">
                                      <img
                                        src={
                                          userdata[3].package_amount > 0
                                            ? Active
                                            : userdata[3].id === ""
                                            ? user3red
                                            : user3red
                                        }
                                        alt=""
                                        onClick={() => (
                                          setIdnumer(userdata[3].id),
                                          getIdArray(userdata[3].id)
                                        )}
                                        className="abc"
                                      />
                                    </button>
                                    <div
                                      className="span"
                                      style={{ color: "#fff" }}
                                    >
                                      {userdata[3].id}
                                    </div>
                                    <div className="dropdown-content ">
                                      <table
                                        className="tables dropdown_width"
                                        cellPadding="0px"
                                        cellSpacing="0px"
                                      >
                                        <tbody>
                                          <tr className="fgtybmd">
                                            <td className="table_heading">
                                              Sponsor :
                                            </td>
                                            <td
                                              className="grtydfbc"
                                              colSpan="3"
                                            >
                                              {userdata[3].Sponsor}
                                            </td>
                                          </tr>
                                          <tr className="fgtybmd">
                                            <td className="table_heading">
                                              Activation Date :
                                            </td>
                                            <td
                                              className="grtydfbc"
                                              colSpan="3"
                                            >
                                              {userdata[3].Activation_date}
                                            </td>{" "}
                                          </tr>
                                          <tr className="fgtybmd">
                                            <td className="table_heading">
                                              Downline :
                                            </td>
                                            <td
                                              className="grtydfbc"
                                              colSpan="3"
                                            >
                                              {userdata[3].Downline}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div
                                className="tree_row1"
                                style={{ height: "34px" }}
                              >
                                <div
                                  className="row_22_child1"
                                  style={{ height: "34px" }}
                                >
                                  <img
                                    src={tree_image_medium}
                                    alt=""
                                    style={{ width: "253px", height: "32px" }}
                                  />
                                </div>
                                <div
                                  className="row_22_child1"
                                  style={{ height: "34px" }}
                                >
                                  <img
                                    src={tree_image_medium}
                                    alt=""
                                    style={{ width: "253px", height: "32px" }}
                                  />
                                </div>
                                <div
                                  className="row_22_child1"
                                  style={{ height: "34px" }}
                                >
                                  <img
                                    src={tree_image_medium}
                                    alt=""
                                    style={{ width: "253px", height: "32px" }}
                                  />
                                </div>
                              </div>

                              <div className="tree_row2">
                                <div className="row_33_child">
                                  <div className="dropdown">
                                    <button className="dropbtn">
                                      <img
                                        src={
                                          userdata[4].package_amount > 0
                                            ? Active
                                            : userdata[4].id === ""
                                            ? user3red
                                            : user3red
                                        }
                                        alt=""
                                        onClick={() => (
                                          setIdnumer(userdata[4].id),
                                          getIdArray(userdata[4].id)
                                        )}
                                        className="abc"
                                      />
                                    </button>
                                    <div
                                      className="span"
                                      style={{ color: "#fff" }}
                                    >
                                      {userdata[4].id}
                                    </div>
                                    <div className="dropdown-content ">
                                      <table
                                        className="tables dropdown_width"
                                        cellPadding="0px"
                                        cellSpacing="0px"
                                      >
                                        <tbody>
                                          <tr className="fgtybmd">
                                            <td className="table_heading">
                                              Sponsor :
                                            </td>
                                            <td
                                              className="grtydfbc"
                                              colSpan="3"
                                            >
                                              {userdata[4].Sponsor}
                                            </td>
                                          </tr>
                                          <tr className="fgtybmd">
                                            <td className="table_heading">
                                              Activation Date :
                                            </td>
                                            <td
                                              className="grtydfbc"
                                              colSpan="3"
                                            >
                                              {userdata[4].Activation_date}
                                            </td>{" "}
                                          </tr>
                                          <tr className="fgtybmd">
                                            <td className="table_heading">
                                              Downline :
                                            </td>
                                            <td
                                              className="grtydfbc"
                                              colSpan="3"
                                            >
                                              {userdata[4].Downline}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                                <div className="row_33_child">
                                  <div className="dropdown">
                                    <button className="dropbtn">
                                      <img
                                        src={
                                          userdata[5].package_amount > 0
                                            ? Active
                                            : userdata[5].id === ""
                                            ? user3red
                                            : user3red
                                        }
                                        alt=""
                                        onClick={() => (
                                          setIdnumer(userdata[5].id),
                                          getIdArray(userdata[5].id)
                                        )}
                                        className="abc"
                                      />
                                    </button>

                                    <div
                                      className="span"
                                      style={{ color: "#fff" }}
                                    >
                                      {/* {userdata[5].name}  <br /> */}

                                      {userdata[5].id}
                                    </div>
                                    <div className="dropdown-content ">
                                      <table
                                        className="tables dropdown_width"
                                        cellPadding="0px"
                                        cellSpacing="0px"
                                      >
                                        <tbody>
                                          <tr className="fgtybmd">
                                            <td className="table_heading">
                                              Sponsor :
                                            </td>
                                            <td
                                              className="grtydfbc"
                                              colSpan="3"
                                            >
                                              {userdata[5].Sponsor}
                                            </td>
                                          </tr>
                                          <tr className="fgtybmd">
                                            <td className="table_heading">
                                              Activation Date :
                                            </td>
                                            <td
                                              className="grtydfbc"
                                              colSpan="3"
                                            >
                                              {userdata[5].Activation_date}
                                            </td>{" "}
                                          </tr>
                                          <tr className="fgtybmd">
                                            <td className="table_heading">
                                              Downline :
                                            </td>
                                            <td
                                              className="grtydfbc"
                                              colSpan="3"
                                            >
                                              {userdata[5].Downline}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                                <div className="row_33_child">
                                  <div className="dropdown">
                                    <button className="dropbtn">
                                      <img
                                        src={
                                          userdata[6].package_amount > 0
                                            ? Active
                                            : userdata[6].id === ""
                                            ? user3red
                                            : user3red
                                        }
                                        alt=""
                                        onClick={() => (
                                          setIdnumer(userdata[6].id),
                                          getIdArray(userdata[6].id)
                                        )}
                                        className="abc"
                                      />
                                    </button>
                                    <div
                                      className="span"
                                      style={{ color: "#fff" }}
                                    >
                                      {/* {userdata[6].name}  <br /> */}

                                      {userdata[6].id}
                                    </div>
                                    <div className="dropdown-content ">
                                      <table
                                        className="tables dropdown_width"
                                        cellPadding="0px"
                                        cellSpacing="0px"
                                      >
                                        <tbody>
                                          <tr className="fgtybmd">
                                            <td className="table_heading">
                                              Sponsor :
                                            </td>
                                            <td
                                              className="grtydfbc"
                                              colSpan="3"
                                            >
                                              {userdata[6].Sponsor}
                                            </td>
                                          </tr>
                                          <tr className="fgtybmd">
                                            <td className="table_heading">
                                              Activation Date :
                                            </td>
                                            <td
                                              className="grtydfbc"
                                              colSpan="3"
                                            >
                                              {userdata[6].Activation_date}
                                            </td>{" "}
                                          </tr>
                                          <tr className="fgtybmd">
                                            <td className="table_heading">
                                              Downline :
                                            </td>
                                            <td
                                              className="grtydfbc"
                                              colSpan="3"
                                            >
                                              {userdata[6].Downline}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                                <div className="row_33_child">
                                  <div className="dropdown">
                                    <button className="dropbtn">
                                      <img
                                        src={
                                          userdata[7].package_amount > 0
                                            ? Active
                                            : userdata[7].id == ""
                                            ? user3red
                                            : user3red
                                        }
                                        alt=""
                                        onClick={() => (
                                          setIdnumer(userdata[7].id),
                                          getIdArray(userdata[7].id)
                                        )}
                                        className="abc"
                                      />
                                    </button>
                                    <div
                                      className="span"
                                      style={{ color: "#fff" }}
                                    >
                                      {/* {userdata[3].name}  <br /> */}

                                      {userdata[7].id}
                                    </div>
                                    <div className="dropdown-content ">
                                      <table
                                        className="tables dropdown_width"
                                        cellPadding="0px"
                                        cellSpacing="0px"
                                      >
                                        <tbody>
                                          <tr className="fgtybmd">
                                            <td className="table_heading">
                                              Sponsor :
                                            </td>
                                            <td
                                              className="grtydfbc"
                                              colSpan="3"
                                            >
                                              {userdata[7].Sponsor}
                                            </td>
                                          </tr>
                                          <tr className="fgtybmd">
                                            <td className="table_heading">
                                              Activation Date :
                                            </td>
                                            <td
                                              className="grtydfbc"
                                              colSpan="3"
                                            >
                                              {userdata[7].Activation_date}
                                            </td>{" "}
                                          </tr>
                                          <tr className="fgtybmd">
                                            <td className="table_heading">
                                              Downline :
                                            </td>
                                            <td
                                              className="grtydfbc"
                                              colSpan="3"
                                            >
                                              {userdata[7].Downline}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                                <div className="row_33_child">
                                  <div className="dropdown">
                                    <button className="dropbtn">
                                      <img
                                        src={
                                          userdata[8].package_amount > 0
                                            ? Active
                                            : userdata[8].id === ""
                                            ? user3red
                                            : user3red
                                        }
                                        alt=""
                                        onClick={() => (
                                          setIdnumer(userdata[8].id),
                                          getIdArray(userdata[8].id)
                                        )}
                                        className="abc"
                                      />
                                    </button>
                                    <div
                                      className="span"
                                      style={{ color: "#fff" }}
                                    >
                                      {/* {userdata[4].name}  <br /> */}

                                      {userdata[8].id}
                                    </div>
                                    <div className="dropdown-content ">
                                      <table
                                        className="tables dropdown_width"
                                        cellPadding="0px"
                                        cellSpacing="0px"
                                      >
                                        <tbody>
                                          <tr className="fgtybmd">
                                            <td className="table_heading">
                                              Sponsor :
                                            </td>
                                            <td
                                              className="grtydfbc"
                                              colSpan="3"
                                            >
                                              {userdata[8].Sponsor}
                                            </td>
                                          </tr>
                                          <tr className="fgtybmd">
                                            <td className="table_heading">
                                              Activation Date :
                                            </td>
                                            <td
                                              className="grtydfbc"
                                              colSpan="3"
                                            >
                                              {userdata[8].Activation_date}
                                            </td>{" "}
                                          </tr>
                                          <tr className="fgtybmd">
                                            <td className="table_heading">
                                              Downline :
                                            </td>
                                            <td
                                              className="grtydfbc"
                                              colSpan="3"
                                            >
                                              {userdata[8].Downline}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                                <div className="row_33_child">
                                  <div className="dropdown">
                                    <button className="dropbtn">
                                      <img
                                        src={
                                          userdata[9].package_amount > 0
                                            ? Active
                                            : userdata[9].id === ""
                                            ? user3red
                                            : user3red
                                        }
                                        alt=""
                                        onClick={() => (
                                          setIdnumer(userdata[9].id),
                                          getIdArray(userdata[9].id)
                                        )}
                                        className="abc"
                                      />
                                    </button>
                                    <div
                                      className="span"
                                      style={{ color: "#fff" }}
                                    >
                                      {/* {userdata[3].name}  <br /> */}

                                      {userdata[9].id}
                                    </div>
                                    <div className="dropdown-content ">
                                      <table
                                        className="tables dropdown_width"
                                        cellPadding="0px"
                                        cellSpacing="0px"
                                      >
                                        <tbody>
                                          <tr className="fgtybmd">
                                            <td className="table_heading">
                                              Sponsor :
                                            </td>
                                            <td
                                              className="grtydfbc"
                                              colSpan="3"
                                            >
                                              {userdata[9].Sponsor}
                                            </td>
                                          </tr>
                                          <tr className="fgtybmd">
                                            <td className="table_heading">
                                              Activation Date :
                                            </td>
                                            <td
                                              className="grtydfbc"
                                              colSpan="3"
                                            >
                                              {userdata[9].Activation_date}
                                            </td>{" "}
                                          </tr>
                                          <tr className="fgtybmd">
                                            <td className="table_heading">
                                              Downline :
                                            </td>
                                            <td
                                              className="grtydfbc"
                                              colSpan="3"
                                            >
                                              {userdata[9].Downline}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                                <div className="row_33_child">
                                  <div className="dropdown">
                                    <button className="dropbtn">
                                      <img
                                        src={
                                          userdata[10].package_amount > 0
                                            ? Active
                                            : userdata[10].id === ""
                                            ? user3red
                                            : user3red
                                        }
                                        alt=""
                                        onClick={() => (
                                          setIdnumer(userdata[10].id),
                                          getIdArray(userdata[10].id)
                                        )}
                                        className="abc"
                                      />
                                    </button>
                                    <div
                                      className="span"
                                      style={{ color: "#fff" }}
                                    >
                                      {/* {userdata[4].name}  <br /> */}

                                      {userdata[10].id}
                                    </div>
                                    <div className="dropdown-content ">
                                      <table
                                        className="tables dropdown_width"
                                        cellPadding="0px"
                                        cellSpacing="0px"
                                      >
                                        <tbody>
                                          <tr className="fgtybmd">
                                            <td className="table_heading">
                                              Sponsor :
                                            </td>
                                            <td
                                              className="grtydfbc"
                                              colSpan="3"
                                            >
                                              {userdata[10].Sponsor}
                                            </td>
                                          </tr>
                                          <tr className="fgtybmd">
                                            <td className="table_heading">
                                              Activation Date :
                                            </td>
                                            <td
                                              className="grtydfbc"
                                              colSpan="3"
                                            >
                                              {userdata[10].Activation_date}
                                            </td>{" "}
                                          </tr>
                                          <tr className="fgtybmd">
                                            <td className="table_heading">
                                              Downline :
                                            </td>
                                            <td
                                              className="grtydfbc"
                                              colSpan="3"
                                            >
                                              {userdata[10].Downline}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                                <div className="row_33_child">
                                  <div className="dropdown">
                                    <button className="dropbtn">
                                      <img
                                        src={
                                          userdata[11].package_amount > 0
                                            ? Active
                                            : userdata[11].id === ""
                                            ? user3red
                                            : user3red
                                        }
                                        alt=""
                                        onClick={() => (
                                          setIdnumer(userdata[11].id),
                                          getIdArray(userdata[11].id)
                                        )}
                                        className="abc"
                                      />
                                    </button>

                                    <div
                                      className="span"
                                      style={{ color: "#fff" }}
                                    >
                                      {/* {userdata[5].name}  <br /> */}

                                      {userdata[11].id}
                                    </div>
                                    <div className="dropdown-content ">
                                      <table
                                        className="tables dropdown_width"
                                        cellPadding="0px"
                                        cellSpacing="0px"
                                      >
                                        <tbody>
                                          <tr className="fgtybmd">
                                            <td className="table_heading">
                                              Sponsor :
                                            </td>
                                            <td
                                              className="grtydfbc"
                                              colSpan="3"
                                            >
                                              {userdata[11].Sponsor}
                                            </td>
                                          </tr>
                                          <tr className="fgtybmd">
                                            <td className="table_heading">
                                              Activation Date :
                                            </td>
                                            <td
                                              className="grtydfbc"
                                              colSpan="3"
                                            >
                                              {userdata[11].Activation_date}
                                            </td>{" "}
                                          </tr>
                                          <tr className="fgtybmd">
                                            <td className="table_heading">
                                              Downline :
                                            </td>
                                            <td
                                              className="grtydfbc"
                                              colSpan="3"
                                            >
                                              {userdata[11].Downline}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                                <div className="row_33_child">
                                  <div className="dropdown">
                                    <button className="dropbtn">
                                      <img
                                        src={
                                          userdata[12].package_amount > 0
                                            ? Active
                                            : userdata[12].id === ""
                                            ? user3red
                                            : user3red
                                        }
                                        alt=""
                                        onClick={() => (
                                          setIdnumer(userdata[12].id),
                                          getIdArray(userdata[12].id)
                                        )}
                                        className="abc"
                                      />
                                    </button>
                                    <div
                                      className="span"
                                      style={{ color: "#fff" }}
                                    >
                                      {/* {userdata[6].name}  <br /> */}

                                      {userdata[12].id}
                                    </div>
                                    <div className="dropdown-content ">
                                      <table
                                        className="tables dropdown_width"
                                        cellPadding="0px"
                                        cellSpacing="0px"
                                      >
                                        <tbody>
                                          <tr className="fgtybmd">
                                            <td className="table_heading">
                                              Sponsor :
                                            </td>
                                            <td
                                              className="grtydfbc"
                                              colSpan="3"
                                            >
                                              {userdata[12].Sponsor}
                                            </td>
                                          </tr>
                                          <tr className="fgtybmd">
                                            <td className="table_heading">
                                              Activation Date :
                                            </td>
                                            <td
                                              className="grtydfbc"
                                              colSpan="3"
                                            >
                                              {userdata[12].Activation_date}
                                            </td>{" "}
                                          </tr>
                                          <tr className="fgtybmd">
                                            <td className="table_heading">
                                              Downline :
                                            </td>
                                            <td
                                              className="grtydfbc"
                                              colSpan="3"
                                            >
                                              {userdata[12].Downline}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default TreeView;
