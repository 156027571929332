import React, { useEffect, useState } from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Navbar from "../Navbar/Navbar";
import MobileNavbar from "../Navbar/MobileNavbar";

import Table from "../Table/Table";
import Table_Buttons from "../Table_Buttons/Table_Button";
import { API } from "../../Utility/API";
import { updateAuth, updateStatus } from "../../Redux/AuthSlice";
import { useDispatch, useSelector } from "react-redux";

export default function StakingLevelBonus() {
  const [ReportApi, setReportApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(50);
  const [totalCount, settotalCount] = useState(50);
  const [level, setLevel] = useState(0);
  const dispatch = useDispatch();

  const user = useSelector((state) => state.Auth.userId);
  const token = useSelector((state) => state.Auth.jwtToken);

  const Report_API = async () => {
    try {
      let responce = await API.post(
        "levelIncome",
        {
          uid: user,
          level: level,
          fdate: "",
          tdate: "",
        },
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json", 
          },
        }
      );
      responce = responce.data.data[0];
      console.log("res-->", responce);
      setReportApi([]);

      let arr = [];
      responce.forEach((item, index) => {
        arr.push({
          sr: item.RowNumber,
          uid: item.uid,
          walletAddress: item.walletAddress,
          fromid: item.sid,
          onamount: item.onamount,
          income: item.income,
          dd: item.dd,
          level: item.lvl,
        });
      });

      setReportApi(arr);
    } catch (e) {
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            isAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
      }
    }
  };

  useEffect(() => {
    Report_API();
  }, [level]);

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = ReportApi.slice(indexOfFirstPage, indexOfLastPost);

  var [reportData, set_reportData] = new useState({
    cols: [
      { Header: "S.No", accessor: "sr" },
      { Header: "User Id", accessor: "fromid" },
      { Header: "From Id", accessor: "uid" },
      { Header: "From Wallet", accessor: "walletAddress" },
      { Header: "On Amount $", accessor: "onamount" },
      { Header: "Income $", accessor: "income" },
      { Header: "Level", accessor: "level" },
      { Header: "Date", accessor: "dd" },
    ],
  });

  return (
    <>
      <Header />
      <Navbar />
      <MobileNavbar />
      <div className="container-fluid  tablecardSection">
        <div className="row">
          <div className="col-12">
            <div className="text-white fw-bold HeadingOtherpage py-sm-4 py-2">
              Level Income
            </div>
          </div>
          <div
            className="row mx-0 align-items-center g-3 mb-sm-4 mb-3"
            style={{ "margin-top": "-25px" }}
          >
            <div className="col-md-3">
              <label className="text-white fs-12 mb-0">Level</label>
              <select
                onChange={(e) => setLevel(e.target.value)}
                className="form-control border-0 text-white bg-theme1 shadow-none"
              >
                <option value={"0"}>All</option>
                <option value={"1"}>Level 1</option>
                <option value={"2"}>Level 2</option>
                <option value={"3"}>Level 3</option>
                <option value={"4"}>Level 4</option>
                <option value={"5"}>Level 5</option>
                <option value={"6"}>Level 6</option>
                <option value={"7"}>Level 7</option>
                <option value={"8"}>Level 8</option>
                <option value={"9"}>Level 9</option>
                <option value={"10"}>Level 10</option>
                <option value={"11"}>Level 11</option>
                <option value={"12"}>Level 12</option>
                <option value={"13"}>Level 13</option>
                <option value={"14"}>Level 14</option>
                <option value={"15"}>Level 15</option>
                <option value={"16"}>Level 16</option>
                <option value={"17"}>Level 17</option>
                <option value={"18"}>Level 18</option>
                <option value={"19"}>Level 19</option>
                <option value={"20"}>Level 20</option>
              </select>
            </div>
          </div>
          <main className="col-12 px-md-3 mt-0">
            <div className="card">
              <div className="card-body">
                <div className="mt-md-3 table_main table-responsive">
                  <Table data={[...ReportApi]} columns={reportData.cols} />
                  <Table_Buttons
                    indexOfFirstPage={indexOfFirstPage}
                    indexOfLastPost={indexOfLastPost}
                    setcurrentPage={setcurrentPage}
                    currentPage={currentPage}
                    totalData={totalCount}
                    listPerpage={listPerpage}
                    handleRowsPerPageChange={setlistPerpage}
                  />
                </div>
              </div>
            </div>
          </main>
          <Footer />
        </div>
      </div>
    </>
  );
}
